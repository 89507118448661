import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { TopicPreview } from '../../models';
import { getTopicUrl } from './Pages';


interface DiscussionBubbleProps {
    topic: TopicPreview
};

const topicClicked = (topic: TopicPreview) => {
    window.location.href = getTopicUrl(topic);
}

export const DiscussionBubble: React.FC<DiscussionBubbleProps> = ({ topic }) => {
    return (
        <Row className="discussion">
            <Col className="avatar" sm={12} md={2} lg={2}>
                <img src="" />
                <p>{topic.authorName}</p>
            </Col>
            <div className="w-100 d-md-none" />
            <Col>
                <Container onClick={() => { topicClicked(topic) }} style={{cursor:"pointer"}} fluid>
                    <blockquote className="speech-bubble">
                        <div className="subject-row">
                            <div className="subject"><a href={getTopicUrl(topic)}>{topic.subject}</a></div>
                            <div className="date">{ topic.created?.toDateString() }</div>
                        </div>
                        <p>{topic.excerpt}</p>
                    </blockquote>
                </Container>
            </Col>
        </Row>
        );
}