import { AstronomyDay, IAstronomyDay } from "./TidesApi.Models.AstronomyDay.gen";

export interface ISolunarPageModel {
	firstDayOfMonth: string;

	astronomyDays: IAstronomyDay[];

}
export class SolunarPageModel {
	constructor(data: ISolunarPageModel) {
		Object.assign(this, data);
		if (!data) return;
		this.firstDayOfMonth = new Date(data.firstDayOfMonth);
		this.astronomyDays = data.astronomyDays.map((a: any) => new AstronomyDay(a));
	}

	firstDayOfMonth: Date;

	astronomyDays: AstronomyDay[];

}

