import { WeatherForecast, IWeatherForecast } from "./WeatherForecast.gen";
import { WaveForecastsByDay, IWaveForecastsByDay } from "./WaveForecastsByDay.gen";
import { FishingSpotCluster, IFishingSpotCluster } from "./FishingSpotCluster.gen";

export interface IOffshoreWaveForecastPageModel {
	weatherForecast: IWeatherForecast[];

	mile50WaveForecasts: IWaveForecastsByDay[];

	mile15WaveForecasts: IWaveForecastsByDay[];

	fishingSpots: IFishingSpotCluster[];

}
export class OffshoreWaveForecastPageModel {
	constructor(data: IOffshoreWaveForecastPageModel) {
		Object.assign(this, data);
		if (!data) return;
		this.weatherForecast = data.weatherForecast.map((w: any) => new WeatherForecast(w));
		this.mile50WaveForecasts = data.mile50WaveForecasts.map((m: any) => new WaveForecastsByDay(m));
		this.mile15WaveForecasts = data.mile15WaveForecasts.map((m: any) => new WaveForecastsByDay(m));
		this.fishingSpots = data.fishingSpots.map((f: any) => new FishingSpotCluster(f));
	}

	weatherForecast: WeatherForecast[];

	mile50WaveForecasts: WaveForecastsByDay[];

	mile15WaveForecasts: WaveForecastsByDay[];

	fishingSpots: FishingSpotCluster[];

}

