import React from 'react';

import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaUser, FaClock } from 'react-icons/fa';
import { TopicPreview } from '../../models';
import { getTopicUrl } from './Pages';

interface FeaturedReportProps {
    topic: TopicPreview
};

export const FeaturedReport: React.FC<FeaturedReportProps> = ({ topic }) => {
    var imageUrl = "";
    if (!topic)
        return <></>;

    if (topic.imageUrls != undefined && topic.imageUrls.length > 0)
        imageUrl = topic.imageUrls[0];
    return (
        <div className="featured-fishing-report">
            <Row>
                <Col className="col-auto">
                    <div className="image">
                        <a href={getTopicUrl(topic)}>
                            <img src={imageUrl} alt={topic.subject} />
                        </a>
                    </div>
                </Col>
                <div className="w-100 d-lg-none" />
                <Col>
                    <Row className="header">
                        <Col>
                            <FaUser scale={2}>
                            </FaUser>
                            { topic.authorName }
                        </Col>
                        <Col>
                            <FaClock className="created-date-icon" />
                            {topic.created?.toDateString()}
                        </Col>
                        <Col>
                            <div className="featured-tag">FEATURED</div>
                        </Col>
                    </Row>
                    <Row className="report">
                        <Col xs={12}>
                            <h2>{topic.subject}</h2>
                            <div dangerouslySetInnerHTML={{ __html: topic.excerpt }} />
                            <div className="fade-out">&nbsp;</div>
                        </Col>
                    </Row>
                    <Row className="read-more-container">
                        <Col>
                            <a className="btn-outline-primary btn" href={getTopicUrl(topic)}>READ MORE</a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}