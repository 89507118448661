import { Sponsor, ISponsor } from "./Sponsor.gen";

export interface ISponsorsPageModel {
	sponsors: ISponsor[];

}
export class SponsorsPageModel {
	constructor(data: ISponsorsPageModel) {
		Object.assign(this, data);
		if (!data) return;
		this.sponsors = data.sponsors.map((s: any) => new Sponsor(s));
	}

	sponsors: Sponsor[];

}

