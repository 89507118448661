import React, { MouseEventHandler } from 'react';
import { Card, CardImg } from 'react-bootstrap';
import { FishingSpot, FishingSpotCluster } from '../../models';

interface FishingSpotCardProps {
    fishingSpot: FishingSpotCluster,
    onClick?: MouseEventHandler
}

export const FishingSpotCard: React.FC<FishingSpotCardProps> = ({ fishingSpot, onClick }) => {

    return (
        <Card className="fishing-spot-card" onClick={onClick}>
            <Card.Body>
                <Card.Title>
                    {(fishingSpot as FishingSpotCluster)?.summary}
                </Card.Title>
                <Card.Text>
                    {fishingSpot.numberOfPoints > 1 &&
                        <>
                            {fishingSpot.numberOfPoints} Reef or Fishing Spot points<br />
                            {fishingSpot.distance.toFixed(2)} mi {fishingSpot.bearingAsCardinal} of Charleston
                        </>
                    }
                    {fishingSpot.numberOfPoints == 1 &&
                        <>
                        {fishingSpot.details?.description}<br />
                        {fishingSpot.distance.toFixed(2)} mi {fishingSpot.bearingAsCardinal} of Charleston<br />
                        Numbers: {fishingSpot.latitude}, {fishingSpot.longitude}
                        </>
                    }
                    
                </Card.Text>
            </Card.Body>
        </Card>
    );
}