import { Compare } from './validators';
import { IsEmail, IsNotEmpty } from 'class-validator';

export interface INewUser {
	emailAddress: string;

	password: string;

	confirmPassword: string;

}
export class NewUser {
	constructor(data: INewUser) {
		Object.assign(this, data);
		if (!data) return;
	}

	@IsNotEmpty()
	@IsEmail({}, {
	  "message": "The $property field is not a valid e-mail address."
	})
	emailAddress: string;

	@IsNotEmpty()
	password: string;

	@IsNotEmpty()
	@Compare("password", {
	  "message": "The passwords do not match."
	})
	confirmPassword: string;

}

