import React, { useMemo, useCallback } from 'react';
import { AreaClosed, Line, Bar } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { GridRows, GridColumns } from '@visx/grid';
import { scaleTime, scaleLinear } from '@visx/scale';
import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { localPoint } from '@visx/event';
import { ParentSize, withParentSize } from '@visx/responsive';
import { LinearGradient } from '@visx/gradient';
import { AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { max, extent, bisector } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { DailyTide, TideForecast } from '../../models';
import { WithParentSizeProps } from '@visx/responsive/lib/enhancers/withParentSize';

type TooltipData = TideForecast;

export const tooltipBackground = '#FFFFFF';
export const lineColor = '#0D588D';
export const accentColor = '#555555';
export const tooltipColor = '#0E75Bd';
export const timeLineColor = '#FF0000';
const tooltipStyles = {
    ...defaultStyles,
    background: tooltipBackground,
    border: '1px solid black',
    color: tooltipColor,
};

// accessors
const getHours = (tf: TideForecast) => tf.hourOfDay;
const getHeight = (tf: TideForecast) => tf.heightMLLW;
const getTimeInHours = (d: Date) => d.getHours() + d.getMinutes() / 60;


export type TideGraphProps = {
    width: number;
    height: number;
    tides: DailyTide;
    currentStationTime?: Date;
    margin?: { top: number; right: number; bottom: number; left: number };
};
export type TideGraphAutoProps = Omit<TideGraphProps, "width" | "height">;

export const TideGraphAutoSize = withTooltip<TideGraphAutoProps>((props: TideGraphAutoProps) => (
    <ParentSize>
        {parent => (
            <TideGraph
                width={parent.width}
                height={parent.height}
                {...props}
            />
        )}
    </ParentSize>
    )
);

export const TideGraph = withTooltip<TideGraphProps, TooltipData>(
    ({
        tides,
        width,
        height,
        margin = { top: 20, right: 25, bottom: 8, left: 40 },
        showTooltip,
        hideTooltip,
        tooltipData,
        currentStationTime,
        tooltipTop = 0,
        tooltipLeft = 0,
    }: TideGraphProps & WithTooltipProvidedProps<TooltipData>) => {
        if (width < 10) return null;
        

        var innerWidth = width - margin.left - margin.right;
        var innerHeight = height - margin.top - margin.bottom;

        // scales
        const xScale = useMemo(
            () =>
                scaleLinear({
                    range: [margin.left, innerWidth + margin.left],
                    domain: [0, 24],
                    nice: true
                }),
            [innerWidth, margin.left],
        );
        
        const yScale = useMemo(
            () =>
                scaleLinear({
                    range: [innerHeight + margin.top, margin.top],
                    domain: extent(tides.hourly, getHeight) as any as [number, number],
                    nice: true
                    
                }),
            [margin.top, innerHeight],
        );

        // tooltip handler
        const handleTooltip = useCallback(
            (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
                const { x } = localPoint(event) || { x: 0 };

                const x0 = xScale.invert(x - 10);
                var data = undefined;
                var approximateIndex = Math.min(Math.max(Math.round(x0 - 1), 0), tides.hourly.length - 1);

                for (var i = approximateIndex; i < tides.hourly.length; i++) {
                    if (tides.hourly[i].hourOfDay > x0) {
                        data = tides.hourly[i];
                        break;
                    }
                }

                if (!data)
                    return;

                showTooltip({
                    tooltipData: data,
                    tooltipLeft: xScale(data.hourOfDay),
                    tooltipTop: yScale(getHeight(data)),
                });
            },
            [showTooltip, yScale, xScale],
        );

        return (
            <div>
                <svg width={width} height={height}>
                    <LinearGradient id="area-gradient" from={lineColor} to={lineColor} toOpacity={0.1} />
                    <GridRows
                        left={margin.left}
                        scale={yScale}
                        width={innerWidth}
                        strokeDasharray="3,3"
                        stroke={accentColor}
                        strokeOpacity={0.3}
                        pointerEvents="none"
                    />
                    <AxisLeft
                        left={margin.left}
                        scale={yScale}
                        stroke={accentColor}
                        tickStroke={accentColor}
                        tickLabelProps={() => ({
                            fill: accentColor,
                            fontSize: 11,
                            textAnchor: 'end',
                        })}
                    />
                    <text x="-70" y="15" transform="rotate(-90)" fontSize={10}>
                        Feet (MLLW)
                    </text>
                    <AreaClosed<TideForecast>
                        data={tides.hourly}
                        x={f => xScale(getHours(f))??0}
                        y={f => yScale(getHeight(f))??0}
                        yScale={yScale}
                        strokeWidth={1}
                        stroke="url(#area-gradient)"
                        fill="url(#area-gradient)"
                        curve={curveMonotoneX}
                    />
                    {currentStationTime && (
                        <Line
                            from={{ x: xScale(getTimeInHours(currentStationTime)), y: margin.top }}
                            to={{ x: xScale(getTimeInHours(currentStationTime)), y: innerHeight + margin.top }}
                            stroke={timeLineColor}
                            strokeWidth={2}
                            pointerEvents="none"
                        />
                        )
                    }
                    <Bar
                        x={0}
                        y={0}
                        width={width}
                        height={height}
                        fill="transparent"
                        rx={14}
                        onTouchStart={handleTooltip}
                        onTouchMove={handleTooltip}
                        onMouseMove={handleTooltip}
                        onMouseLeave={() => hideTooltip()}
                    />
                    {tides.extrema && tides.extrema.map(tf => (
                        <g key={tf.hourOfDay}>
                            <circle
                                cx={xScale(tf.hourOfDay)}
                                cy={yScale(tf.heightMLLW)}
                                r={8}
                                fill={tooltipColor}
                                stroke="white"
                                strokeWidth={2}
                                pointerEvents="none"
                            />
                            <text x={xScale(tf.hourOfDay)}
                                y={(yScale(tf.heightMLLW)??0) - 10}
                                fontSize={13}
                                textAnchor="middle"
                            >
                                {`${tf.heightMLLW.toFixed(2)} ft`}
                            </text>
                            
                        </g>
                    ))}
                    {tooltipData && (
                        <g>
                            <Line
                                from={{ x: tooltipLeft, y: margin.top }}
                                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                                stroke={tooltipColor}
                                strokeWidth={2}
                                pointerEvents="none"
                                strokeDasharray="5,2"
                            />
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop + 1}
                                r={4}
                                fill="black"
                                fillOpacity={0.1}
                                stroke="black"
                                strokeOpacity={0.1}
                                strokeWidth={2}
                                pointerEvents="none"
                            />
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop}
                                r={4}
                                fill={tooltipColor}
                                stroke="white"
                                strokeWidth={2}
                                pointerEvents="none"
                            />
                        </g>
                    )}
                </svg>
                {tooltipData && (
                    <div>
                        <Tooltip top={tooltipTop - 30} left={tooltipLeft + 5} style={tooltipStyles}>
                            {`${getHeight(tooltipData).toFixed(2)} ft`}
                        </Tooltip>
                        <Tooltip
                            top={innerHeight + margin.top - 10 }
                            left={tooltipLeft - 72 / 2 }
                            style={{
                                ...defaultStyles,
                                minWidth: 72,
                                textAlign: 'center',
                            }}
                        >
                            {tooltipData.timeAsString}
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    }
);
