import { TopicPreview, ITopicPreview } from "./TopicPreview.gen";
import { WeatherForecast, IWeatherForecast } from "./WeatherForecast.gen";
import { Station, IStation } from "./TidesApi.Models.Station.gen";
import { DailyTide, IDailyTide } from "./TidesApi.Models.DailyTide.gen";
import { Sponsor, ISponsor } from "./Sponsor.gen";

export interface IIndexPageModel {
	reports: ITopicPreview[];

	discussions: ITopicPreview[];

	weatherForecast: IWeatherForecast[];

	tideStation: IStation;

	dailyTideForecasts: IDailyTide[];

	sponsors: ISponsor[];

}
export class IndexPageModel {
	constructor(data: IIndexPageModel) {
		Object.assign(this, data);
		if (!data) return;
		this.reports = data.reports.map((r: any) => new TopicPreview(r));
		this.discussions = data.discussions.map((d: any) => new TopicPreview(d));
		this.weatherForecast = data.weatherForecast.map((w: any) => new WeatherForecast(w));
		this.tideStation = new Station(data.tideStation);
		this.dailyTideForecasts = data.dailyTideForecasts.map((d: any) => new DailyTide(d));
		this.sponsors = data.sponsors.map((s: any) => new Sponsor(s));
	}

	reports: TopicPreview[];

	discussions: TopicPreview[];

	weatherForecast: WeatherForecast[];

	tideStation: Station;

	dailyTideForecasts: DailyTide[];

	sponsors: Sponsor[];

}

