import { TideForecastType } from "./TidesApi.Models.TideForecastType.gen";

export interface ITideForecast {
	timeAsString: string;

	timeAsDate: string;

	hourOfDay: number;

	heightMLLW: number;

	type: TideForecastType;

	isHigh: boolean;

	isLow: boolean;

	isExtrema: boolean;

}
export class TideForecast {
	constructor(data: ITideForecast) {
		Object.assign(this, data);
		if (!data) return;
		this.timeAsDate = new Date(data.timeAsDate);
	}

	timeAsString: string;

	timeAsDate: Date;

	hourOfDay: number;

	heightMLLW: number;

	type: TideForecastType;

	isHigh: boolean;

	isLow: boolean;

	isExtrema: boolean;

}

