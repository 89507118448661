import { CardinalDirections } from "./CardinalDirections.gen";

export interface IWaveForecast {
	date: string|undefined;

	description: string;

	avgWindKnot: number|undefined;

	maxWindKnot: number|undefined;

	avgWindMph: number|undefined;

	maxWindMpg: number|undefined;

	avgWindDirection: number|undefined;

	avgWindDirectionCardinal: CardinalDirections;

	maxWaveHeightMeter: number|undefined;

	minWaveHeightMeter: number|undefined;

	avgWaveHeightMeter: number|undefined;

	maxWaveHeightFeet: number|undefined;

	minWaveHeightFeet: number|undefined;

	avgWaveHeightFeet: number|undefined;

	avgWaveDirection: number|undefined;

	avgWaveDirectionCardinal: CardinalDirections;

	avgWavePeriodSeconds: number|undefined;

	timeOfDay: TimesOfDay;

}
export class WaveForecast {
	constructor(data: IWaveForecast) {
		Object.assign(this, data);
		if (!data) return;
		if (data.date != null) { this.date = new Date(data.date); }
	}

	date: Date|undefined;

	description: string;

	avgWindKnot: number|undefined;

	maxWindKnot: number|undefined;

	avgWindMph: number|undefined;

	maxWindMpg: number|undefined;

	avgWindDirection: number|undefined;

	avgWindDirectionCardinal: CardinalDirections;

	maxWaveHeightMeter: number|undefined;

	minWaveHeightMeter: number|undefined;

	avgWaveHeightMeter: number|undefined;

	maxWaveHeightFeet: number|undefined;

	minWaveHeightFeet: number|undefined;

	avgWaveHeightFeet: number|undefined;

	avgWaveDirection: number|undefined;

	avgWaveDirectionCardinal: CardinalDirections;

	avgWavePeriodSeconds: number|undefined;

	timeOfDay: TimesOfDay;

}

export enum TimesOfDay {
	Overnight = 0,
	Morning = 1,
	Afternoon = 2,
	Evening = 3,
}

