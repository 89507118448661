
import React from 'react';

import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaUser, FaClock } from 'react-icons/fa';
import { TopicPreview } from '../../models';
import { getForumUrl, getTopicUrl } from './Pages';

interface FishingReportProps {
    topic: TopicPreview
};

const imageClicked = (topic: TopicPreview) => {
    window.location.href = getTopicUrl(topic);
}

export const FishingReport: React.FC<FishingReportProps> = ({ topic }) => {
    return (
        <div className="fishing-report">
            <Row>
                <Col sm={12} md={5} style={{ backgroundImage: `url('${topic.imageUrls[0]}')`, backgroundSize: "cover", backgroundPosition: "center center", minHeight: "200px", cursor: "pointer" }} onClick={() => { imageClicked(topic); }}>
                    <div className="image" />
                </Col>
                <div className="w-100 d-block d-md-none"></div>
                <Col sm={12} md={7} className="pl-0 pl-md-3">
                    <Row className="header">
                        <Col>
                            <FaUser/>
                            {topic.authorName}
                        </Col>
                        <Col style={{ textAlign: "right" }}>
                            <FaClock style={{ verticalAlign: "middle", fontSize: "20px", paddingBottom: "1px" }} />
                            {topic.created?.toDateString()}
                        </Col>
                    </Row>
                    <Row className="report">
                        <Col>
                            <a href={getTopicUrl(topic)}>
                                <h2>{topic.subject}</h2>
                            </a>
                            {topic.excerpt}
                            <div className="fade-out">&nbsp;</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
