import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { usePageModel } from '../../hooks';
import { FishingSpotPageModel, WaveForecastsByDay, TimesOfDay } from '../../models';
import { staticRenderContext } from '../../StaticRenderContext';
import { ContentScreen } from '../layouts';
import { BodyRow, BodyRowContent, BodyRowHeader, WaveForecastDay } from '../shared';
import { Map } from '../shared/Map';

const waveForecastByDayToElement = (wfByDay: WaveForecastsByDay, index: number) => {
    if (wfByDay.forecasts.filter(wf => wf.timeOfDay == TimesOfDay.Morning || wf.timeOfDay == TimesOfDay.Afternoon).length < 1)
        return <></>;
    return (
        <>
            <WaveForecastDay key={wfByDay.date.toDateString()} date={wfByDay.date} forecasts={wfByDay.forecasts} />
            {index % 2 == 1 &&
                <div className="w-100 d-lg-none" />
            }
            {(index % 2 == 0) &&
                <div className="w-100 d-md-none" />
            }
        </>
    );
}

const FishingSpot: React.FC<{}> = (props) => {
    const staticContext = useContext(staticRenderContext);
    var model = usePageModel(FishingSpotPageModel, staticContext);

    if (!model)
        return <></>;

    const spot = model.spot;
    return (
        <ContentScreen>
            
            <Map containerId="locationMap" icons={[spot]} centerLat={spot.latitude} centerLng={spot.longitude} />
            <Row className="location-details">
                <Col xs={6}>
                    <h1 className="location-title">{spot.title}</h1>
                    <h2 className="location-description">{spot.description}</h2>
                    
                </Col>
                <Col>
                    <h1 className="location-coords">{spot.latitude},&nbsp;{spot.longitude}</h1>
                </Col>
            </Row>
                
            <BodyRow>
                <BodyRowHeader hideSeparator={true}>
                    <Col>
                        <h1>5-Day Marine Wave Forecast</h1>
                    </Col>
                </BodyRowHeader>
                <BodyRowContent className="p-3">
                    {
                        model.waveForecasts.map(waveForecastByDayToElement)
                    }
                    <Col className="d-lg-none wave-forecast-day"></Col>
                </BodyRowContent>
            </BodyRow>
            
        </ContentScreen>
    );
};

export default FishingSpot;