import React, { useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { WeatherDayCard } from '.';
import { useContainerDimensions } from '../../hooks';
import { WeatherForecast } from '../../models';

interface WeatherDayCardsProps {
    forecasts: WeatherForecast[]
}

export const WeatherDayCards: React.FC<WeatherDayCardsProps> = ({ forecasts }) => {
    var weatherCards = useRef(null);
    var [selectedCard, setSelectedCard] = useState(0);
    var [startIndex, setStartIndex] = useState(0);
    var { width, height } = useContainerDimensions(weatherCards);

    //500: width of expanded, 160: width of collapsed, 40 width of next button
    var maxCards = Math.floor((width - 500 - 40) / 160) + 1;
     
    function onScrollClicked(incr: number) {
        startIndex += incr;
        if (selectedCard >= maxCards)
            setSelectedCard(Math.max(maxCards - 1, 0));
        setStartIndex(startIndex);
    }
    function forecastsToRender(wf: WeatherForecast, i: number) {
        //If this is not the start index, and is not going to fit, do not render it
        if (i != startIndex && (i < startIndex || (i - startIndex) > (maxCards - 1)))
            return false;
        return true;
    }

    function forecastToCard(wf: WeatherForecast, i:number) {
        return <WeatherDayCard key={wf.date.getTime()} forecast={wf}
            collapsed={i != selectedCard}
            onClick={() => { setSelectedCard(i) }} />
    }

    return (
        <Col className="weather-cards" ref={weatherCards} >
            {   startIndex > 0 &&
                <div className="weather-card-next" onClick={() => onScrollClicked(-1)}>
                    <p>
                        <FaAngleLeft />
                    </p>
                </div>
            }

            {forecasts.filter(forecastsToRender).map(forecastToCard)}

            {    startIndex + maxCards  < forecasts.length && 
                <div className="weather-card-next" onClick={() => onScrollClicked(1)}>
                    <p>
                        <FaAngleRight />
                    </p>
                </div>
            }
        </Col>
    );
}