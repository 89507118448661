import { BoatLanding, IBoatLanding } from "./BoatLanding.gen";

export interface IBoatLandingPageModel {
	landing: IBoatLanding;

}
export class BoatLandingPageModel {
	constructor(data: IBoatLandingPageModel) {
		Object.assign(this, data);
		if (!data) return;
		this.landing = new BoatLanding(data.landing);
	}

	landing: BoatLanding;

}

