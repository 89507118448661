import { TideForecast, ITideForecast } from "./TidesApi.Models.TideForecast.gen";

export interface IDailyTide {
	localDay: string;

	extrema: ITideForecast[];

	hourly: ITideForecast[];

}
export class DailyTide {
	constructor(data: IDailyTide) {
		Object.assign(this, data);
		if (!data) return;
		this.localDay = new Date(data.localDay);
		this.extrema = data.extrema.map((e: any) => new TideForecast(e));
		this.hourly = data.hourly.map((h: any) => new TideForecast(h));
	}

	localDay: Date;

	extrema: TideForecast[];

	hourly: TideForecast[];

}

