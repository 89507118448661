import { useState, useEffect } from 'react';

const defaultSettings = {
    enableHighAccuracy: false,
    timeout: Infinity,
    maximumAge: 0,
};

export type PositionInfo = {
    timestamp: number;
    latitude: number;
    longitude: number;
    accuracy: number;
    speed: number | null;
    heading: number | null;
};

export const usePosition = (watch = false, userSettings = {}) => {
    const settings = {
        ...defaultSettings,
        ...userSettings,
    };

    const [position, setPosition] = useState<PositionInfo>();
    const [error, setError] = useState<string>();

    const onChange: PositionCallback = ({ coords, timestamp }) => {
        setPosition({
            latitude: coords.latitude,
            longitude: coords.longitude,
            accuracy: coords.accuracy,
            speed: coords.speed,
            heading: coords.heading,
            timestamp,
        });
    };

    const onError: PositionErrorCallback = (error) => {
        setError(error.message);
    };

    useEffect(() => {
        if (!navigator || !navigator.geolocation) {
            setError('Geolocation is not supported');
            return;
        }

        if (watch) {
            const watcher =
                navigator.geolocation.watchPosition(onChange, onError, settings);
            return () => navigator.geolocation.clearWatch(watcher);
        }

        navigator.geolocation.getCurrentPosition(onChange, onError, settings);
    }, [
        settings.enableHighAccuracy,
        settings.timeout,
        settings.maximumAge,
    ]);

    return { ...position, error };
};