type SessionValueResult<T> = [
    value: T | undefined,
    setValue: SessionSetter<T>
];

export type SessionSetter<T> = (value: T | undefined) => void;

export function sessionValue<T>(key: string): SessionValueResult<T> {
    const setValue = (value: T | undefined) => {
        const jsonValue = JSON.stringify(value);
        window.sessionStorage.setItem(key, jsonValue);
    };

    const currentValue = window.sessionStorage.getItem(key);

    if (currentValue === null || currentValue === 'undefined') {
        return [undefined, setValue];
    }

    const parsedValue = JSON.parse(currentValue);
    return [parsedValue, setValue];
}