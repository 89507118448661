import React, { useContext } from 'react';
import { CardDeck, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getZoomForRadius } from '../../helpers/map';
import { usePageModel } from '../../hooks';
import { FishingSpotCluster, OffshoreWaveForecastPageModel, WaveForecastsByDay, TimesOfDay } from '../../models';
import { staticRenderContext } from '../../StaticRenderContext';

import { ContentScreen } from '../layouts/ContentScreen';
import { BodyRow, BodyRowContent, BodyRowHeader, FishingSpotCard, WaveForecastDay, WeatherDayCards } from '../shared';
import { Pages } from '../shared/Pages';

const waveForecastByDayToElement = (wfByDay: WaveForecastsByDay, index: number) => {
    if (wfByDay.forecasts.filter(wf => wf.timeOfDay == TimesOfDay.Morning || wf.timeOfDay == TimesOfDay.Afternoon).length < 1)
        return <></>;
    return (
        <>
            <WaveForecastDay key={wfByDay.date.toDateString()} date={wfByDay.date} forecasts={wfByDay.forecasts} />
            {index % 2 == 1 &&
                <div className="w-100 d-lg-none" />
            }
            {(index % 2 == 0) &&
                <div className="w-100 d-md-none" />
            }
        </>
    );
}

const handleSpotClick = (fsc: FishingSpotCluster) => {
  
    if (fsc.numberOfPoints == 1) {
        window.location.href = Pages.FishingSpot.getUrl({ locationId: fsc.details.id })
    } else {
        //zoom map in and center on this lat lon
        var zoom = getZoomForRadius(fsc.radius);
        window.location.href = Pages.FishingSpots.getUrl({ zoom: zoom, latitude: fsc.latitude, longitude: fsc.longitude });
    }
};


const OffshoreWaveForecast: React.FC = (props) => {
    const staticContext = useContext(staticRenderContext);
    var model = usePageModel(OffshoreWaveForecastPageModel, staticContext);

    if (!model)
        return <></>;

    return (
        <ContentScreen>
            { (model.weatherForecast.length > 0) &&
                <BodyRow>
                    <BodyRowHeader>
                        <Col>
                            <h1>
                                Charleston SC Coastal Weather Forecast
                        </h1>
                        </Col>
                    </BodyRowHeader>
                    <BodyRowContent>
                        <WeatherDayCards forecasts={model.weatherForecast} />
                    </BodyRowContent>
                </BodyRow>
            }
            <BodyRow>
                <BodyRowHeader>
                    <Col>
                        <h1>15 Mile Offshore Wave Forecast</h1>
                    </Col>
                </BodyRowHeader>
                <BodyRowContent className="p-3">
                    {
                        model.mile15WaveForecasts.map(waveForecastByDayToElement)
                    }
                    <Col className="d-lg-none wave-forecast-day"></Col>
                </BodyRowContent>
            </BodyRow>
            <BodyRow>
                <BodyRowHeader>
                    <Col>
                        <h1>50 Mile Offshore Wave Forecast</h1>
                    </Col>
                </BodyRowHeader>
                <BodyRowContent className="p-3">
                    {
                        model.mile50WaveForecasts.map(waveForecastByDayToElement)
                    }
                    <Col className="d-lg-none wave-forecast-day"></Col>
                </BodyRowContent>
            </BodyRow>
            <BodyRow>
                <BodyRowHeader>
                    <Col>
                        <h1>Offshore Reefs and Fishing Spots</h1>
                    </Col>
                </BodyRowHeader>
                <BodyRowContent>
                    <Col>
                        If you know where you are going to be fishing offshore, you can get a more accurate wave forecast.  
                        Below are some nearshore reefs and offshore fishing spots.  If you do not see where you will be fishing, try
                        our <a href={Pages.FishingSpots.path}>fishing spots and reefs map</a>.
                    </Col>
                </BodyRowContent>
                <BodyRowContent>
                    <Col>
                        <CardDeck>
                            {model && model.fishingSpots &&
                                model.fishingSpots.map((fsc) => {
                                    return (<FishingSpotCard fishingSpot={fsc} onClick={() => { handleSpotClick(fsc); }} />);
                                })
                            }
                        </CardDeck>
                    </Col>
                </BodyRowContent>
            </BodyRow>
                
        </ContentScreen>
    );
}

export default OffshoreWaveForecast;