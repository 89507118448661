import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { usePageModel } from '../../hooks';
import { BoatLandingPageModel } from '../../models';
import { ContentScreen } from '../layouts';
import { BodyRow, BodyRowContent, BodyRowHeader, DataCard } from '../shared';
import { Map } from '../shared/Map';
import { FaCar } from 'react-icons/fa';
import { staticRenderContext } from '../../StaticRenderContext';

const BoatLanding: React.FC = (props) => {
    const staticContext = useContext(staticRenderContext);
    var model = usePageModel(BoatLandingPageModel, staticContext);


    if (!model || !model.landing) {
        window.location.href = "/sc-boat-landings";
        return <></>;
    }

    const landing = model.landing;
    
    return (
        <ContentScreen>
            <Map containerId="locationMap" icons={[landing]} centerLat={landing.latitude} centerLng={landing.longitude} />
            <Row className="location-details">
                <Col xs={6}>
                    <h1 className="location-title">{landing.title}</h1>
                    <h2 className="location-description">{landing.bodyOfWater}</h2>

                </Col>
                <Col>
                    <h1 className="location-coords">{landing.latitude},&nbsp;{landing.longitude}</h1>
                </Col>
            </Row>


            <BodyRow className="boat-ramp-container">
                <BodyRowContent>
                    <Col className="col-auto">
                        <img src={"/img/sc-boat-ramps/" + landing.imageUrl} className="boat-ramp-image-large" />
                    </Col>
                    <div className="w-100 d-md-none" />
                    <Col className="boat-ramp-details">
                        
                            <h4>Description:</h4>
                            <p>
                                {landing.description}
                            </p>
                            <h4>Address:</h4>
                            <p>
                                {landing.address}<br/>
                                {landing.cityStateZip}<br/>
                                {landing.county} County
                            </p>
                        
                    </Col>
                </BodyRowContent>
            </BodyRow>
            <BodyRow className="boat-ramp-amenities">
                <BodyRowHeader hideSeparator={false}>
                    <Col>
                        <h1>Boat Ramp Amenities</h1>
                    </Col>
                </BodyRowHeader>
                <BodyRowContent>
                    <Col sm={12} md={6} lg={4}>
                        <DataCard title="Parking" value={landing.numberParkingSpots + " spots"} icon="car" />
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <DataCard title="Boat Ramps" value={landing.numberOfLanes} icon="lane" />
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <DataCard title="Courtesy Dock" value={landing.hasCourtesyDock ? "Yes" : "No"} icon="pier" />
                    </Col><Col sm={12} md={6} lg={4}>
                        <DataCard title="Fishing Pier" value={landing.hasFishingPier ? "Yes" : "No"} icon="fish" />
                    </Col>
                </BodyRowContent>
            </BodyRow>
            <BodyRow>
                <BodyRowHeader >
                    <Col>
                        <h1>Nearby SC Boat Landings</h1>
                    </Col>
                </BodyRowHeader>
                <BodyRowContent>
                </BodyRowContent>
            </BodyRow>
        </ContentScreen>
    );
};

export default BoatLanding;