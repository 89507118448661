import { WaveForecast, IWaveForecast } from "./WaveForecast.gen";

export interface IWaveForecastsByDay {
	date: string;

	forecasts: IWaveForecast[];

}
export class WaveForecastsByDay {
	constructor(data: IWaveForecastsByDay) {
		Object.assign(this, data);
		if (!data) return;
		this.date = new Date(data.date);
		this.forecasts = data.forecasts.map((f: any) => new WaveForecast(f));
	}

	date: Date;

	forecasts: WaveForecast[];

}

