import React, { useContext } from 'react';

import { ContentScreen } from '../layouts';
import { FeaturedReport, FishingReport, WeatherDayCards, DiscussionBubble, BodyRow, BodyRowContent, BodyRowHeader, TidesBodyRow } from '../shared';
import { IndexPageModel, IIndexPageModel, TopicPreview } from '../../models';
import { FaCaretSquareDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { usePageModel } from '../../hooks';
import { getForumUrl, Pages } from '../shared/Pages';
import { staticRenderContext } from '../../StaticRenderContext';

const Home: React.FC = (props) => {
    const numberOfSponsorsToDisplay = 3;
    const staticContext = useContext(staticRenderContext);
    var model = usePageModel(IndexPageModel, staticContext);

    if (!model)
        return <ContentScreen>Loading...</ContentScreen>;

    if (model.sponsors && model.sponsors.length > numberOfSponsorsToDisplay)
        model.sponsors.splice(numberOfSponsorsToDisplay);

    return (
        <ContentScreen>
            <BodyRow>
                <FeaturedReport topic={model.reports[0]} />
            </BodyRow>

            {model.sponsors &&
                <BodyRow>
                    <BodyRowHeader>
                        <Col sm={8} md={9}>
                            <h1>Charleston Fishing Sponsors</h1>
                        </Col>
                        <Col sm={4} md={3} className="settings">
                            <a href={Pages.Sponsors.path}>All Fishing Sponsors<FaCaretSquareDown /></a>
                        </Col>
                    </BodyRowHeader>
                    <Row className="home-sponsor-row">
                        {model.sponsors && model.sponsors.map(sponsor => (
                                    <Col key={sponsor.id}>
                                        <a href={sponsor.url}>
                                            <img src={`/img/sponsors/${sponsor.logo}`} alt={sponsor.name} />
                                        </a>
                                        <p>{sponsor.tagline}</p>
                                    </Col>
                        ))}
                    </Row>
                </BodyRow>
            }
            <BodyRow>
                <BodyRowHeader>
                    <Col sm={8} md={9}>
                        <h1>Fishing Reports by Local Anglers</h1>
                    </Col>
                    <Col sm={4} md={3} className="settings">
                        <Link to={getForumUrl()}>All Fishing Reports <FaCaretSquareDown /></Link>
                    </Col>
                </BodyRowHeader>
                <BodyRowContent>
                    {model.reports.map((report, i) => {
                        return (
                            <React.Fragment key={report.id}>
                                <Col sm={12} lg={6} >
                                    <FishingReport topic={report} />
                                </Col>
                                {(i + 1) % 2 == 0 ? <div className="w-100"></div> : <></>}
                            </React.Fragment>
                        );
                    })}
                </BodyRowContent>
            </BodyRow>

            <TidesBodyRow station={model.tideStation} tides={model.dailyTideForecasts} />

            <BodyRow>
                <BodyRowHeader>
                    <Col sm={8} md={9}>
                        <h1>Charleston Fishing Discussions</h1>
                    </Col>
                    <Col sm={4} md={3} className="settings">
                        <a href={getForumUrl()}>All Fishing Discussions <FaCaretSquareDown /></a>
                    </Col>
                </BodyRowHeader>
                    {
                        model.discussions.map((discussion, i) => {
                            return (
                                <DiscussionBubble topic={discussion} key={discussion.id} />
                            );
                        })
                    }
            </BodyRow>

            {(model.weatherForecast.length > 0) &&
                <BodyRow>
                    <BodyRowHeader>
                        <Col sm={9} md={10}>
                            <h1>Fishing Weather for Charleston, SC</h1>{/*TODO: (<a href="#" style={{ display: "inline" }}>change</a>)*/}
                        </Col>
                        <Col sm={3} md={2} className="settings">
                            {/*TODO: <a href="#"><i className="fas fa-sliders-h" style={{ fontSize: "20px" }}></i></a> */}
                        </Col>
                    </BodyRowHeader>
                    <BodyRowContent>
                        <WeatherDayCards forecasts={model.weatherForecast} />
                    </BodyRowContent>
                </BodyRow>
            }
        </ContentScreen>
    );
}

export default Home;