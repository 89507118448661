import { FishingSpotCluster, IFishingSpotCluster } from "./FishingSpotCluster.gen";

export interface IFishingSpotsPageModel {
	reefs: IFishingSpotCluster[];

}
export class FishingSpotsPageModel {
	constructor(data: IFishingSpotsPageModel) {
		Object.assign(this, data);
		if (!data) return;
		this.reefs = data.reefs.map((r: any) => new FishingSpotCluster(r));
	}

	reefs: FishingSpotCluster[];

}

