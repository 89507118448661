import { createContext, PropsWithChildren, useState } from 'react';
import React from 'react';

export const staticRenderContext = createContext<StaticRenderContextInfo | undefined>(undefined);

export interface StaticRenderContextInfo {
    title?: string;
    data?: any;
}

export const StaticRenderContextProvider: React.FC<PropsWithChildren<StaticRenderContextInfo>> = (props) => {
    
    return (
        <staticRenderContext.Provider value={{ title: props.title, data: props.data}}>
            {props.children}
        </staticRenderContext.Provider>
    );
}
