import React, { ReactPropTypes } from 'react';
import { Col, Row } from 'react-bootstrap';

type MonthlyCalendarProps = {
    title: string,
    month?: Date,
    children(day: Date): React.ReactNode
}
const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
};
export const MonthlyCalendar: React.FC<MonthlyCalendarProps> = (props) => {
    var today = new Date();
    var dayOfMonth = props.month ?? new Date();
    //Put the day to the first day of the month, 12:00am
    dayOfMonth = new Date(dayOfMonth.getFullYear(), dayOfMonth.getMonth(), 1);

    //Get what we need to make the calendar
    var startingDayOfMonth = dayOfMonth.getDay();
    var daysInMonth = getDaysInMonth(dayOfMonth.getFullYear(), dayOfMonth.getMonth());
    var fullWeeks = Math.ceil((daysInMonth + startingDayOfMonth) / 7.0);
    var daysInCalendar = fullWeeks * 7;
    var weeks: { [key: number]: (Date | null)[] } = {};

    for (var i = 0; i < fullWeeks; i++)
        weeks[i] = [];

    //Add days before this month
    for (var i = 0; i < startingDayOfMonth; i++)
        weeks[0].push(null);

    //Add days for this month
    for (var i = 0; i < daysInMonth; i++) {
        var weekIndex = Math.floor((i + startingDayOfMonth) / 7);
        var currentDate = new Date(dayOfMonth.getFullYear(), dayOfMonth.getMonth(), i + 1);
        weeks[weekIndex].push(currentDate);
    }
    //Add days after this month
    for (var i = daysInMonth; i < daysInCalendar - startingDayOfMonth; i++) {
        var weekIndex = Math.floor((i + startingDayOfMonth) / 7);
        weeks[weekIndex].push(null);
    }

    return (
        <div className="monthly-calendar">
            <Row className="calendar-title">
                <Col>
                    <h2>
                        {props.title}
                    </h2>
                </Col>
            </Row>
            <Row className="weeknames d-none d-md-flex">
                <Col>
                    Sunday
                                    </Col>
                <Col>
                    Monday
                                    </Col>
                <Col>
                    Tuesday
                                    </Col>
                <Col>
                    Wednesday
                                    </Col>
                <Col>
                    Thursday
                                    </Col>
                <Col>
                    Friday
                                    </Col>
                <Col>
                    Saturday
                                    </Col>
            </Row>
            <Row className="week">
            {Object.keys(weeks).map((i) => (
                <React.Fragment key={props.month?.getMonth() + "-" + i}>
                    {weeks[+i].map((date, dateNum) => (
                        <Col key={dateNum} className={`day col-6 col-md ${(today.getDate() == date?.getDate() ? "today" : "")}`} >
                            <div className="day-number">
                                {date && date.getDate()}
                            </div>
                            <div className="day-contents">
                                {date && props.children(date)}
                            </div>
                        </Col>
                    ))}
                    <div className="w-100 d-none d-md-block" />
                </React.Fragment>
            ))}
            </Row>    
            
        </div>
    );
}