import React, { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BodyRow, BodyRowContent, BodyRowHeader } from '.';
import { DailyTide, Station, TideForecast } from '../../models';
import { TideGraphAutoSize } from './TideGraph';

export type TidesBodyRowProps =
{
        station: Station,
        tides: DailyTide[]
}

export const TidesBodyRow: React.FC<TidesBodyRowProps> = (props) => {
    return (
        <BodyRow>
            <BodyRowHeader>
                <Col xs={9} md={10}>
                    <h1>TIDES FOR CHARLESTON, SC</h1> {/*TODO: (<a href="#" style={{ display: "inline" }}>change</a>)*/}
                </Col>
                <Col xs={3} md={2} className="settings">
                    {/*TODO: <a href="#"><i className="fas fa-sliders-h" style={{ fontSize: "20px" }}></i></a>*/}
                </Col>
            </BodyRowHeader>
            {(props.tides.length > 0) &&
                <BodyRowContent>

                    <Col className="tides-chart" lg={8}>
                        <TideGraphAutoSize tides={props.tides[0]} currentStationTime={props.station.currentTime} />
                    </Col>
                
                    <Col className="tides-text" lg={4}>
                       
                        <h2>Today's Tides</h2>
                       
                        {props.tides[0].extrema.map(df => {
                            return (
                                <Row key={df.timeAsDate.getTime()} >
                                    <Col>{df.timeAsString}</Col>
                                    <Col>{df.isHigh ? "High" : "Low"}</Col>
                                    <Col>{df.heightMLLW.toFixed(1)} ft</Col>
                                </Row>
                            );

                        })}
                        <h3>{props.tides[1].localDay.toLocaleDateString("en", { weekday: 'long' })}'s Tides </h3>
                        {props.tides[1].extrema.map(df => {
                            return (
                                <Row key={df.timeAsDate.getTime()} >
                                    <Col>{df.timeAsString}</Col>
                                    <Col>{df.isHigh ? "High" : "Low"}</Col>
                                    <Col>{df.heightMLLW.toFixed(1)} ft</Col>
                                </Row>
                            );

                        })}
                    </Col>
                </BodyRowContent>
            }
        </BodyRow>
        );
}