export interface ITopicPreview {
	id: number;

	categoryId: number;

	parentCategoryId: number;

	subject: string;

	messageRaw: string;

	messageHtml: string;

	excerpt: string;

	authorId: number;

	authorName: string;

	created: string|undefined;

	isArchived: boolean;

	imageUrls: string[];

}
export class TopicPreview {
	constructor(data: ITopicPreview) {
		Object.assign(this, data);
		if (!data) return;
		if (data.created != null) { this.created = new Date(data.created); }
	}

	id: number;

	categoryId: number;

	parentCategoryId: number;

	subject: string;

	messageRaw: string;

	messageHtml: string;

	excerpt: string;

	authorId: number;

	authorName: string;

	created: Date|undefined;

	isArchived: boolean;

	imageUrls: string[];

}

