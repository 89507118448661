import { MapIconTypes } from "./MapIconTypes.gen";

export interface IBoatLanding {
	mapIcon: MapIconTypes;

	title: string;

	description: string;

	latitude: number;

	longitude: number;

	county: string;

	address: string;

	cityStateZip: string;

	imageUrl: string;

	bodyOfWater: string;

	numberOfLanes: number;

	numberParkingSpots: number;

	hasFishingPier: boolean;

	hasCourtesyDock: boolean;

	id: string;

}
export class BoatLanding {
	constructor(data: IBoatLanding) {
		Object.assign(this, data);
		if (!data) return;
	}

	mapIcon: MapIconTypes;

	title: string;

	description: string;

	latitude: number;

	longitude: number;

	county: string;

	address: string;

	cityStateZip: string;

	imageUrl: string;

	bodyOfWater: string;

	numberOfLanes: number;

	numberParkingSpots: number;

	hasFishingPier: boolean;

	hasCourtesyDock: boolean;

	id: string;

}

