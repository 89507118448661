import { BoatLandingCluster, IBoatLandingCluster } from "./BoatLandingCluster.gen";

export interface IBoatLandingsPageModel {
	landings: IBoatLandingCluster[];

}
export class BoatLandingsPageModel {
	constructor(data: IBoatLandingsPageModel) {
		Object.assign(this, data);
		if (!data) return;
		this.landings = data.landings.map((l: any) => new BoatLandingCluster(l));
	}

	landings: BoatLandingCluster[];

}

