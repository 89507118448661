import { FishingSpot, IFishingSpot } from "./FishingSpot.gen";
import { WaveForecastsByDay, IWaveForecastsByDay } from "./WaveForecastsByDay.gen";
import { WeatherForecast, IWeatherForecast } from "./WeatherForecast.gen";

export interface IFishingSpotPageModel {
	spot: IFishingSpot;

	waveForecasts: IWaveForecastsByDay[];

	weatherForecast: IWeatherForecast;

}
export class FishingSpotPageModel {
	constructor(data: IFishingSpotPageModel) {
		Object.assign(this, data);
		if (!data) return;
		this.spot = new FishingSpot(data.spot);
		this.waveForecasts = data.waveForecasts.map((w: any) => new WaveForecastsByDay(w));
		this.weatherForecast = new WeatherForecast(data.weatherForecast);
	}

	spot: FishingSpot;

	waveForecasts: WaveForecastsByDay[];

	weatherForecast: WeatherForecast;

}

