export interface IWeatherForecast {
	date: string;

	dateName: string;

	weatherbitCode: number;

	isDaytime: boolean;

	tempLow: number;

	tempHigh: number;

	chanceOfPrecipitation: number;

	windSpeed: number;

	windSpeedGust: number;

	windDirection: number;

	windDirectionCardinal: string;

	waveDirection: number;

	waveDirectionCardinal: string;

	waveHeightMin: number;

	waveHeightMax: number;

	wavePeriod: number;

	currentTemperature: number|undefined;

	feelsLikeTemperature: number|undefined;

}
export class WeatherForecast {
	constructor(data: IWeatherForecast) {
		Object.assign(this, data);
		if (!data) return;
		this.date = new Date(data.date);
	}

	date: Date;

	dateName: string;

	weatherbitCode: number;

	isDaytime: boolean;

	tempLow: number;

	tempHigh: number;

	chanceOfPrecipitation: number;

	windSpeed: number;

	windSpeedGust: number;

	windDirection: number;

	windDirectionCardinal: string;

	waveDirection: number;

	waveDirectionCardinal: string;

	waveHeightMin: number;

	waveHeightMax: number;

	wavePeriod: number;

	currentTemperature: number|undefined;

	feelsLikeTemperature: number|undefined;

}

