import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { StaticRenderContextInfo } from '../StaticRenderContext';

type ModelFactory<T extends {}> = { new(...args: any[]): T };
export function usePageModel<T>(modelFactory: ModelFactory<T>, staticContext: StaticRenderContextInfo | undefined) {
    const getInitialValue = () => {
        if (staticContext != undefined)
            return new modelFactory(staticContext.data);

        if (!window || !window.__ROUTE_DATA__) {
            return undefined;
        }

        var model = new modelFactory(window.__ROUTE_DATA__);
        window.__REFRESH_ROUTE_DATA__ = true;
        return model;
    }
    
    const [model, setModel] = useState(getInitialValue);
    const location = useLocation();
    //Load data if we do not have it
    useEffect(() => {
        if (!model || window.__REFRESH_ROUTE_DATA__) {
            var path = `${location.pathname}/pagedata`;
            if (!location.pathname || location.pathname === "/" || location.pathname === "")
                path = "pagedata";
            fetch(path)
                .then(r => r.json())
                .then(apiResponse => {
                    //TODO: Check for errors
                    if (!apiResponse || !apiResponse.data)
                        return;

                    setModel(new modelFactory(apiResponse.data));
                });
        }
    }, [location]);
    return model;
}