import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { usePageModel } from '../../hooks';
import { SolunarPageModel } from '../../models';
import { ContentScreen } from '../layouts';
import { BodyRow, BodyRowContent, BodyRowHeader } from '../shared';
import { MonthlyCalendar } from '../shared';
import { FiSunrise, FiSunset } from 'react-icons/fi';
import {
    WiMoonrise, WiMoonset, WiMoonAltNew, WiMoonAltFirstQuarter, WiMoonAltFull, WiMoonAltThirdQuarter,
    WiMoonAltWaningCrescent1, WiMoonAltWaningCrescent2, WiMoonAltWaningCrescent3, WiMoonAltWaningCrescent4, WiMoonAltWaningCrescent5, WiMoonAltWaningCrescent6,
    WiMoonAltWaningGibbous1, WiMoonAltWaningGibbous2, WiMoonAltWaningGibbous3, WiMoonAltWaningGibbous4, WiMoonAltWaningGibbous5, WiMoonAltWaningGibbous6,
    WiMoonAltWaxingCrescent1, WiMoonAltWaxingCrescent2, WiMoonAltWaxingCrescent3, WiMoonAltWaxingCrescent4, WiMoonAltWaxingCrescent5, WiMoonAltWaxingCrescent6,
    WiMoonAltWaxingGibbous1, WiMoonAltWaxingGibbous2, WiMoonAltWaxingGibbous3, WiMoonAltWaxingGibbous4, WiMoonAltWaxingGibbous5, WiMoonAltWaxingGibbous6
} from 'react-icons/wi';
import { IconBaseProps } from 'react-icons';
import { Pages } from '../shared/Pages';
import { staticRenderContext } from '../../StaticRenderContext';

const SolunarCalendar: React.FC = (props) => {
    const staticContext = useContext(staticRenderContext);
    var model = usePageModel(SolunarPageModel, staticContext);

    const getTimeFromHour = (fractionalHour: number) => {
        var hour = Math.floor(fractionalHour);
        var minute = Math.floor((fractionalHour - hour) * 60);
        var amPm = "AM";
        if (hour > 12) {
            amPm = "PM";
            hour -= 12;
        }
        return `${hour}:${minute < 10 ? "0" + minute : minute} ${amPm}`;
    };

    //NewMoon (0) -> Waxing Crescent -> First Quarter (25%) -> Waxing Gibbous -> Full Moon (50%) -> Waning Gibbous -> Third Quarter(75%) -> Waning Crescent
    const moonPhases = [
        WiMoonAltNew,
        WiMoonAltWaxingCrescent1, WiMoonAltWaxingCrescent2, WiMoonAltWaxingCrescent3, WiMoonAltWaxingCrescent4, WiMoonAltWaxingCrescent5, WiMoonAltWaxingCrescent6,
        WiMoonAltFirstQuarter,
        WiMoonAltWaxingGibbous1, WiMoonAltWaxingGibbous2, WiMoonAltWaxingGibbous3, WiMoonAltWaxingGibbous4, WiMoonAltWaxingGibbous5, WiMoonAltWaxingGibbous6,
        WiMoonAltFull,
        WiMoonAltWaningGibbous1, WiMoonAltWaningGibbous2, WiMoonAltWaningGibbous3, WiMoonAltWaningGibbous4, WiMoonAltWaningGibbous5, WiMoonAltWaningGibbous6,
        WiMoonAltThirdQuarter,
        WiMoonAltWaningCrescent1, WiMoonAltWaningCrescent2, WiMoonAltWaningCrescent3, WiMoonAltWaningCrescent4, WiMoonAltWaningCrescent5, WiMoonAltWaningCrescent6];
    
    const getMoonIcon = (moonPhase?: string, ageInDays?: number) => {

        var iconProps: IconBaseProps = {};
        if (!moonPhase || !ageInDays)
            return <></>;

        switch (moonPhase) {
            case "New Moon":
                return moonPhases[0](iconProps);
            case "First Quarter":
                return moonPhases[7](iconProps);
            case "Full Moon":
                return moonPhases[14](iconProps);
            case "Third Quarter":
                return moonPhases[21](iconProps);
        }

        var moonIconIndex = Math.round((ageInDays / 29.5) * moonPhases.length);

        //Don't want these icons to show up more than once
        if (moonIconIndex == 0 || moonIconIndex == 7 || moonIconIndex == 14 || moonIconIndex == 21)
            moonIconIndex++;
        return moonPhases[moonIconIndex](iconProps);
    }

    const getDayContents = (day: Date) => {
        var ad = model?.astronomyDays[day.getDate() - 1];
        if (ad == null)
            return <></>;

        return <>
            <div className="moonIcon">
                {getMoonIcon(ad?.moonPhase, ad?.moonAgeInDays)}
            </div>
           
            <div className="sunrise">
                <FiSunrise /> {getTimeFromHour(ad?.sunRise)}
            </div>
            <div className="sunset">
                <FiSunset /> {getTimeFromHour(ad?.sunSet)}
            </div>
            <div className="moonrise">
                <WiMoonrise /> {getTimeFromHour(ad?.moonRise)}
            </div>
            <div className="moonset">
                <WiMoonset /> {getTimeFromHour(ad?.moonSet)}
            </div>
        </>;
    }


    return (
        <ContentScreen>
            <BodyRow>
                <BodyRowHeader>
                    <Col>
                        <h1>Solunar Fishing Calendar</h1>
                    </Col>
                </BodyRowHeader>
                <BodyRowContent>
                    <Col>
                        <p>
                            With many fish species in and around Charleston feeding during the night, considering what the moon and sun are doing can help you to catch more fish.  During a full moon it is common for redfish, sea trout, and offshore pelagic fish such as mahi and tuna to feed through the night during a full moon.  If you try to go fishing early the next morning, you will likely be trying to catch fish that have been feeding all night!
                        </p>
                        <p>
                            Here is a calendar with the sunrise, sunset, moonrise, and moonset that should help you plan which days you want to go fishing.  If you need tide data to help plan, have a look at our <a href={Pages.Tides.getUrl({ stationId: "charleston" })}>Charleston Tides</a> for a comprehensive forecast of tides.
                        </p>

                        <MonthlyCalendar title={model?.firstDayOfMonth?.toLocaleString('default', { month: 'long', year: 'numeric' })??"" + " Solunar Fishing Calendar"} month={model?.firstDayOfMonth} children={getDayContents} />
                    </Col>
                </BodyRowContent>
            </BodyRow>
        </ContentScreen>
    );
}

export default SolunarCalendar;