import React from 'react';
import { Button, Row, Col, Container, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FooterLinkGroup, FooterLink } from '.';
import { TiSocialFacebookCircular, TiSocialTwitterCircular } from 'react-icons/ti';
import { ReactComponent as Logo } from '../../assets/images/Charleston-Fishing-Logo.svg';
import { Forums, getForumUrl, Pages } from './Pages';

export function Footer() {
    return (
        <footer>
            <Container className="footer-container" fluid>
                <Row>
                    <Col xs={12} sm={12} md={8} className="footer-links-col">
                        <div className="footer-links">
                            <Row>
                                <Col md={3}>
                                    <FooterLinkGroup title="Inshore">
                                        <FooterLink href="sc-boat-ramps">SC Boat Ramps</FooterLink>
                                        <FooterLink href={getForumUrl(Forums.InshoreReports)}>Inshore Reports</FooterLink>
                                        <FooterLink href={getForumUrl(Forums.InshoreDiscussion)}>Inshore Discussion</FooterLink>
                                        {/*TODO: <FooterLink href="#">Book a Charleston Inshore Charter</FooterLink>*/}
                                    </FooterLinkGroup>
                                </Col>
                                <Col md={3}>
                                    <FooterLinkGroup title="Offshore">
                                        <FooterLink href={Pages.FishingSpots.getUrl({})}>SC Nearshore Reefs</FooterLink>
                                        <FooterLink href={getForumUrl(Forums.OffshoreReports)}>Offshore Reports</FooterLink>
                                        <FooterLink href={getForumUrl(Forums.OffshoreDiscussion)}>Offshore Discussion</FooterLink>
                                        {/*TODO: <FooterLink href="#">Book a Charleston Offshore Charter</FooterLink>*/}
                                    </FooterLinkGroup>
                                </Col>
                                <Col md={3}>
                                    <FooterLinkGroup title="Freshwater">
                                        <FooterLink href={Pages.BoatLandings.getUrl({ zoom: 10, latitude: 33.5, longitude: -80.35 })}>Lake Boat Ramps</FooterLink>
                                        <FooterLink href={getForumUrl(Forums.FreshwaterDiscussion)}>Freshwater Reports</FooterLink>
                                        <FooterLink href={getForumUrl(Forums.FreshwaterDiscussion)}>Freshwater Discussion</FooterLink>
                                    </FooterLinkGroup>
                                </Col>
                                <Col md={3}>
                                    <FooterLinkGroup title="Conditions">
                                        <FooterLink href={Pages.SolunarCalendar.getUrl()}>Solunar Calendar</FooterLink>
                                        <FooterLink href={Pages.Tides.getUrl({})}>Tides</FooterLink>
                                        {/*TODO: <FooterLink href="#">Marine Weather Forecast</FooterLink>*/}
                                        <FooterLink href={Pages.OffshoreWaveForecast.getUrl()}>Charleston Wave Forecast</FooterLink>
                                    </FooterLinkGroup>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <div className="w-100 d-md-none" />
                    <Col xs={12} sm={12} md={4} className="footer-contact-us-col">
                        <div className="footer-contact-us">
                            <h2>Drop us a Line</h2>
                            <p>Subscribe to our fishing newsletter to receive weekly news, updates, and special offers or discounts from our sponsors:</p>
                            <form action="https://Com.us2.list-manage.com/subscribe/post?u=b61088b094f5312a58022680a&amp;id=493c816dd6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                <Row className="subscribe-form">
                                    <Col xs={6}>
                                        <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
                                        <div style={{ position: 'absolute', left: -5000 } } aria-hidden="true"><input type="text" name="b_b61088b094f5312a58022680a_493c816dd6" tabIndex={-1} defaultValue="" /></div>
                                    </Col>
                                    <Col xs={6}>
                                        <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="btn btn-primary" />
                                    </Col>
                                </Row>
                            </form>
                            <p className="email">Or you can always email us at email@charlestonfishing.com</p>
                        </div>
                    </Col>
                  
                </Row>
                <Row className="footer-bottom">
                    <Col className="left">
                        <a href="https://www.facebook.com/CharlestonFishingCom-278136872326/">
                            <TiSocialFacebookCircular fontSize={40} />
                        </a>
                        <a href="https://twitter.com/charlestonfish">
                            <TiSocialTwitterCircular fontSize={40} />
                        </a>
                    </Col>
                    <Col className="center">
                        <Logo width="100%" height={40} />
                    </Col>
                    <Col className="right">
                        &copy; {new Date().getFullYear()}
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}