export interface IAstronomyDay {
	day: string;

	sunRise: number;

	sunSet: number;

	moonRise: number;

	moonSet: number;

	moonPhase: string;

	moonPhaseLong: string;

	moonIllumination: number;

	moonAgeInDays: number;

}
export class AstronomyDay {
	constructor(data: IAstronomyDay) {
		Object.assign(this, data);
		if (!data) return;
		this.day = new Date(data.day);
	}

	day: Date;

	sunRise: number;

	sunSet: number;

	moonRise: number;

	moonSet: number;

	moonPhase: string;

	moonPhaseLong: string;

	moonIllumination: number;

	moonAgeInDays: number;

}

