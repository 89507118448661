import React from "react"
import { Col } from "react-bootstrap";
import { CardinalDirections, TimesOfDay, WaveForecast } from "../../models";

type WaveForecastTextProps = {
    date: Date,
    forecasts: WaveForecast[]
}

const waveForecastToElement = (wf: WaveForecast) => {
    
    return (
        <>
            <div className="wave-forecast-text">
                <p><b>{TimesOfDay[wf.timeOfDay]}</b></p>
                <p>{wf.description}</p>
                <p><b>Winds:</b> {CardinalDirections[wf.avgWindDirectionCardinal]} {wf.avgWindKnot} kt</p>
                    <p><b>Seas:</b> {CardinalDirections[wf.avgWaveDirectionCardinal]} {wf.avgWaveHeightFeet} feet at {wf.avgWavePeriodSeconds}s</p>
            </div>
            { wf.timeOfDay == TimesOfDay.Morning &&
                <hr/>
            }
        </>
    );
}

export const WaveForecastDay = ({ date, forecasts }: WaveForecastTextProps) => {
    var toDisplay = forecasts.filter(wf => wf.timeOfDay == TimesOfDay.Morning || wf.timeOfDay == TimesOfDay.Afternoon);

    return (
        <>
            {toDisplay.length > 0 &&
                <Col className="wave-forecast-day">
                    <h1>{date.toLocaleDateString(undefined, { weekday: 'short', month: '2-digit', day: 'numeric' })}</h1>
                    {
                        toDisplay.map(waveForecastToElement)
                    }
                </Col>
            }
        </>
    );
}