import { MapIconTypes } from "./MapIconTypes.gen";
import { FishingSpotType } from "./FishingSpotType.gen";

export interface IFishingSpot {
	mapIcon: MapIconTypes;

	spotType: FishingSpotType;

	title: string;

	description: string;

	latitude: number;

	longitude: number;

	symbol: string;

	externalId: string;

	id: string;

}
export class FishingSpot {
	constructor(data: IFishingSpot) {
		Object.assign(this, data);
		if (!data) return;
	}

	mapIcon: MapIconTypes;

	spotType: FishingSpotType;

	title: string;

	description: string;

	latitude: number;

	longitude: number;

	symbol: string;

	externalId: string;

	id: string;

}

