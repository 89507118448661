import React, { useContext, useState } from 'react';
import { usePageModel } from '../../hooks';
import { TidesPageModel, Station } from '../../models';
import { ContentScreen } from '../layouts';
import { BodyRow, BodyRowContent, BodyRowHeader } from '../shared';
import { Row, Col, Container } from 'react-bootstrap';
import { Pages } from '../shared/Pages';
import { Map, Bounds, MapUrlParamType, ParseMapUrlParams, ParseMapQueryString } from '../shared/Map';
import { getShortMonthAndDay } from '../../helpers/dateTime';
import { Tidess } from '../../api';
import { staticRenderContext } from '../../StaticRenderContext';
import { useSearchParams } from 'react-router-dom';
import { TideGraphAutoSize } from '../shared/TideGraph';

const iconClicked = (station: Station) => {
    var stationId = (station && station.name) ? `${station.name}-${station.id}` : undefined;
    var newHref = Pages.Tides.getUrl({ stationId: stationId });
    window.location.href = newHref;
}

const Tides: React.FC = (props) => {
    const staticContext = useContext(staticRenderContext);
    const [searchParams] = useSearchParams();
    var model = usePageModel(TidesPageModel, staticContext);
    const urlParams = ParseMapQueryString(searchParams);

    var currentStation = model?.tideStation;

    const [stations, setStations] = useState(model?.stations);
    const [zoom, setZoom] = useState(urlParams.zoom ?? 12);
    const [latitude, setLatitude] = useState(urlParams.latitude ?? currentStation?.latitude ?? undefined);
    const [longitude, setLongitude] = useState(urlParams.longitude ?? currentStation?.longitude ?? undefined);

    if (currentStation == null) {
        window.location.href = Pages.Tides.getUrl({});
        return <></>;
    }

    const popup = (station: Station) => {
        return (
            <div className="station-popup">
                <Container className="popup-title" fluid>
                    {station.name}
                    <br />
                    {station.currentTideFeet.toFixed(1)}ft, {station.currentTideDirection}
                </Container>
                
            </div>
        );
    }

    const isStationActive = (station: Station) => {
        return station.id == currentStation?.id;
    }

    const mapChanged = (zoom: number, bounds: Bounds) => {
        //Load new points for our map
        setZoom(zoom);
        var centerLat = (bounds.ne.lat + bounds.sw.lat) / 2.0;
        var centerLng = (bounds.nw.lng + bounds.se.lng) / 2.0;
        var stations = Tidess.getTideStations(zoom, centerLat, centerLng, Math.abs(bounds.ne.lat - centerLat), Math.abs(bounds.ne.lng - centerLng));

        stations.then((value: Station[] | undefined) => {
            if (value)
                setStations(value);
        });

        var stationId = (currentStation && currentStation.name) ? `${currentStation.name}-${currentStation.id}` : undefined;
        var args = {};
        if (stationId)
            args = { stationId: stationId };

        window.history.replaceState(null, "", Pages.Tides.getUrlWithQuery(args, { z: zoom.toString(), lat: centerLat.toFixed(5), lng: centerLng.toFixed(5) }));
    };

    return (
        <ContentScreen>
            <Map containerId="locationMap"
                popup={popup}
                icons={stations}
                centerLat={latitude}
                centerLng={longitude}
                zoom={zoom}
                onChange={mapChanged}
                isIconActive={isStationActive}
                onIconClick={iconClicked}
            />
            {currentStation.name &&
                <Row className="location-details">
                    <Col xs={6}>
                        <h1 className="location-title">{currentStation.name} Tides</h1>
                        <h2 className="location-description">Estimated Tide: {currentStation.currentTideFeet.toFixed(2)} ft, {currentStation.currentTideDirection}</h2>

                    </Col>
                    <Col>
                        <h1 className="location-coords">{currentStation.latitude.toFixed(2)},&nbsp;{currentStation.longitude.toFixed(2)}</h1>
                    </Col>
                </Row>
            }
            {model && model.dailyTideForecasts && model.dailyTideForecasts.length > 0 &&
                <>
                    <BodyRow>
                    <BodyRowHeader>
                        <Col>
                            <h1>Today's Tides</h1>
                            {/*<div className="float-right">*/}
                            {/*    <a target="_blank" href="https://apps.apple.com/us/app/tide-charts/id957143504?itsct=apps_box_badge&amp;itscg=30200"*/}
                            {/*        style={{ overflow: "hidden", borderRadius: "13px", width: "250px", height: "83px" }}>*/}
                            {/*        <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1424563200&h=5ee624c379a2be30b4331ff9e2c980cd" alt="Tide Charts" />*/}
                            {/*    </a>*/}
                            {/*    <a href='https://play.google.com/store/apps/details?id=com.SeventhGear.tides&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' style={{height: 60}} src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>*/}
                            {/*</div>*/}
                        </Col>
                        </BodyRowHeader>
                        <BodyRowContent>
                            <Col className="tides-graph">
                            <TideGraphAutoSize currentStationTime={currentStation.currentTime} tides={model?.dailyTideForecasts[0]} />
                            </Col>
                        </BodyRowContent>
                    </BodyRow>
                    <BodyRow>
                        <BodyRowHeader>
                            <Col>
                                <h1>14-Day Tide Forecast</h1>
                            </Col>
                        </BodyRowHeader>
                        <BodyRowContent>
                            <Col>
                                <Row>
                                    {model?.dailyTideForecasts.map((dtf, i) => (
                                        <>
                                            <Col key={dtf.localDay.getTime()} className="tides-day" lg={2} md={4} sm={6}>
                                                <div className="tides-title">
                                                    {getShortMonthAndDay(dtf.localDay)}
                                                </div>
                                                <div className="tides-text">
                                                    {dtf.extrema.map(te => (
                                                        <div key={te.timeAsDate.getTime()}
                                                            className={`tide${te.isHigh ? " tide-high" : " tide-low"}`}>
                                                            {te.heightMLLW.toFixed(1)}ft @ {te.timeAsString} ({(te.isHigh ? "H" : "L")})
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </>
                                    ))}
                                </Row>
                            </Col>
                        </BodyRowContent>
                    </BodyRow>
                </>
            }
        </ContentScreen>
    );
}
export default Tides;