import { BoatLanding, IBoatLanding } from "./BoatLanding.gen";
import { MapIconTypes } from "./MapIconTypes.gen";

export interface IBoatLandingCluster {
	mapIcon: MapIconTypes;

	bearingAsCardinal: string;

	summary: string;

	numberOfPoints: number;

	longitude: number;

	latitude: number;

	radius: number;

	distance: number;

	bearing: number;

	details: IBoatLanding;

}
export class BoatLandingCluster {
	constructor(data: IBoatLandingCluster) {
		Object.assign(this, data);
		if (!data) return;
		this.details = new BoatLanding(data.details);
	}

	mapIcon: MapIconTypes;

	bearingAsCardinal: string;

	summary: string;

	numberOfPoints: number;

	longitude: number;

	latitude: number;

	radius: number;

	distance: number;

	bearing: number;

	details: BoatLanding;

}

