export interface IStation {
	id: string;

	name: string;

	latitude: number;

	longitude: number;

	currentTime: string|undefined;

	currentTideFeet: number;

	currentTideDirection: string;

}
export class Station {
	constructor(data: IStation) {
		Object.assign(this, data);
		if (!data) return;
		if (data.currentTime != null) { this.currentTime = new Date(data.currentTime); }
	}

	id: string;

	name: string;

	latitude: number;

	longitude: number;

	currentTime: Date|undefined;

	currentTideFeet: number;

	currentTideDirection: string;

}

