import React from 'react';
import { Link } from 'react-router-dom'
import { Navbar, NavLink, NavItem, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { ReactComponent as Logo } from '../../assets/images/Charleston-Fishing-Logo.svg';
import { Forums, getForumUrl, Pages } from './Pages';

export function Header() {
    return (
        <header>
            <Navbar variant="dark" expand="lg">
                { /*<nav className="navbar navbar-expand-lg navbar-dark">*/}
                <Navbar.Brand>
                    <a href="/" className="navbar-brand"><Logo style={{ height: 50, width: 200 }} height={50} width={200} /></a>
                </Navbar.Brand>

                { /*Collapsed navbar button*/}
                <Navbar.Toggle aria-controls="navbarSupportedContent">
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>

                <Navbar.Collapse id="navbarSupportedContent" className="justify-content-end">
                    <Nav className="justify-content-end">
                        <NavDropdown id="charlestonFishing" title="Fishing Reports" aria-label="Charleston Fishing Reports">
                            <NavDropdown.Item href={getForumUrl(Forums.InshoreReports)}>Inshore Fishing Reports</NavDropdown.Item>
                            <NavDropdown.Item href={getForumUrl(Forums.OffshoreReports)}>Offshore Fishing Reports</NavDropdown.Item>
                            
                            
                            
                        </NavDropdown>
                        <NavDropdown id="charlestonFishingMaps" title="Maps" aria-label="Fishing Maps">
                            <NavDropdown.Item href={Pages.FishingSpots.path}>SC Fishing Spots and Reefs</NavDropdown.Item>
                            <NavDropdown.Item href={Pages.BoatLandings.path}>SC Boat Ramps</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown id="fishingWeather" title="Weather" aria-label="Fishing Weather" alignRight>
                            <NavDropdown.Item href={Pages.OffshoreWaveForecast.path}>Offshore Wave Forecast</NavDropdown.Item>
                            
                            <NavDropdown.Item href={Pages.SolunarCalendar.path}>Astronomy &amp; Solunar Calendar</NavDropdown.Item>
                            <NavDropdown.Item href={Pages.Tides.path}>Tides</NavDropdown.Item>
                            {/*<NavDropdown.Item href="/noaa-live-buoy-data">Live Buoy Data</NavDropdown.Item>*/}
                            {/*<NavDropdown.Divider />*/}
                            {/*<NavDropdown.Item href="/offshore/charters">Offshore Fishing Charters</NavDropdown.Item>*/}
                            {/*<NavDropdown.Item href="/inshore/charters">Inshore Fishing Charters</NavDropdown.Item>*/}
                        </NavDropdown>
                        <NavDropdown id="fishingForums" title="Forums" aria-label="SC Fishing Discussion" alignRight>
                            <NavDropdown.Item href={getForumUrl()}>All Fishing Forums</NavDropdown.Item>
                            <NavDropdown.Item href={getForumUrl(Forums.InshoreReports)}>Inshore Fishing Reports</NavDropdown.Item>
                            <NavDropdown.Item href={getForumUrl(Forums.InshoreDiscussion)}>Inshore Fishing Discussion</NavDropdown.Item>
                            <NavDropdown.Item href={getForumUrl(Forums.OffshoreReports)}>Offshore Fishing Reports</NavDropdown.Item>
                            <NavDropdown.Item href={getForumUrl(Forums.OffshoreDiscussion)}>Offshore Fishing Discussion</NavDropdown.Item>
                            <NavDropdown.Item href={getForumUrl(Forums.FreshwaterDiscussion)}>Freshwater Fishing Discussion</NavDropdown.Item>
                        </NavDropdown>

                    </Nav>
                    {/*
                    <Dropdown className="navbar-text" alignRight={true}>
                        <Dropdown.Toggle id="searchDropdown" variant="link">
                            <FaSearch />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <form className="form-inline my-2 my-lg-0 m-2">
                                <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                                <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                            </form>
                        </Dropdown.Menu>
                    </Dropdown>*/}
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
}