import { Compare } from './validators';
import { IsNotEmpty } from 'class-validator';

export interface IChangeUserPassword {
	currentPassword: string;

	newPassword: string;

	passwordVerification: string;

}
export class ChangeUserPassword {
	constructor(data: IChangeUserPassword) {
		Object.assign(this, data);
		if (!data) return;
	}

	@IsNotEmpty()
	currentPassword: string;

	@IsNotEmpty()
	newPassword: string;

	@IsNotEmpty()
	@Compare("password", {
	  "message": "The passwords do not match."
	})
	passwordVerification: string;

}

