import React, { useContext } from 'react';
import { Card, CardGroup, Col, Row } from 'react-bootstrap';
import { shuffleArray } from '../../helpers/array';
import { usePageModel } from '../../hooks';
import { SponsorsPageModel } from '../../models';
import { staticRenderContext } from '../../StaticRenderContext';
import { ContentScreen } from '../layouts';
import { BodyRow, BodyRowContent, BodyRowHeader } from '../shared';


const Sponsors: React.FC = (props) => {
    const staticContext = useContext(staticRenderContext);
    var model = usePageModel(SponsorsPageModel, staticContext);

    if (!model)
        return <></>;

    return (
        <ContentScreen>
            <BodyRow>
                <BodyRowHeader>
                    <h1>Charleston Fishing Sponsors</h1>
                </BodyRowHeader>
                <BodyRowContent>
                    <p>Please consider using the services of our sponsors, they keep Charleston Fishing free and enable us to gather content enhance to our website, ultimately helping you to catch more fish.<br/><br/>In other words, if you want to catch more fish in Charleston, or South Carolina for that matter, please consider using the services of one of the companies below!</p>
                </BodyRowContent>
                {
                    model.sponsors.map((s) => (
                        <Row className="sponsor-row">
                            <Col sm={12} md={6} lg={5} className="logo-col">
                                <a href={s.url}>
                                    <img src={`/img/sponsors/${s.logo}`} alt={s.name} />
                                </a>
                            </Col>
                            <Col sm={12} md={6} lg={7}>
                                <a href={s.url}>
                                    <h2>{s.name}</h2>
                                </a>
                                    <p>{s.tagline}</p>
                                
                            </Col>
                        </Row>
                    )) 
                }
            </BodyRow>
        </ContentScreen>
    );
}
export default Sponsors;
