import React from 'react';

import { ReactComponent as ClearDay } from '../../assets/images/weather/clear-day.svg';
import { ReactComponent as ClearNight } from '../../assets/images/weather/clear-night.svg';
import { ReactComponent as Cloudy } from '../../assets/images/weather/cloudy.svg';
import { ReactComponent as Drizzle } from '../../assets/images/weather/drizzle.svg';
import { ReactComponent as Hail } from '../../assets/images/weather/hail.svg';
import { ReactComponent as Mist } from '../../assets/images/weather/mist.svg';
import { ReactComponent as PartlyCloudyDay } from '../../assets/images/weather/partly-cloudy-day.svg';
import { ReactComponent as PartlyCloudyDrizzleDay } from '../../assets/images/weather/partly-cloudy-day-drizzle.svg';
import { ReactComponent as PartlyCloudyHailDay } from '../../assets/images/weather/partly-cloudy-day-hail.svg';
import { ReactComponent as PartlyCloudyRainDay } from '../../assets/images/weather/partly-cloudy-day-rain.svg';
import { ReactComponent as PartlyCloudySnowDay } from '../../assets/images/weather/partly-cloudy-day-snow.svg';
import { ReactComponent as PartlyCloudyNight } from '../../assets/images/weather/partly-cloudy-night.svg';
import { ReactComponent as PartlyCloudyDrizzleNight } from '../../assets/images/weather/partly-cloudy-night-drizzle.svg';
import { ReactComponent as PartlyCloudyHailNight } from '../../assets/images/weather/partly-cloudy-night-hail.svg';
import { ReactComponent as PartlyCloudyRainNight } from '../../assets/images/weather/partly-cloudy-night-rain.svg';
import { ReactComponent as PartlyCloudySnowNight } from '../../assets/images/weather/partly-cloudy-night-snow.svg';
import { ReactComponent as Rain } from '../../assets/images/weather/rain.svg';
import { ReactComponent as Snow } from '../../assets/images/weather/snow.svg';
import { ReactComponent as Thunderstorms } from '../../assets/images/weather/thunderstorms.svg';

//TODO: Maybe take wind speed as an argument and use this if we have a clear day?
//import { ReactComponent as Wind } from '../../assets/images/weather/wind.svg';

interface WeatherIconMap {
    [key: number]: { day: React.FC<React.SVGProps<SVGSVGElement>>, night: React.FC<React.SVGProps<SVGSVGElement>> }
}

const ICONS: WeatherIconMap = {
    200: { day: Thunderstorms, night: Thunderstorms },
    201: { day: Thunderstorms, night: Thunderstorms },
    202: { day: Thunderstorms, night: Thunderstorms },
    230: { day: Thunderstorms, night: Thunderstorms },
    231: { day: Thunderstorms, night: Thunderstorms },
    232: { day: Thunderstorms, night: Thunderstorms },
    233: { day: Thunderstorms, night: Thunderstorms },

    300: { day: PartlyCloudyDrizzleDay, night: PartlyCloudyDrizzleNight },
    301: { day: PartlyCloudyDrizzleDay, night: PartlyCloudyDrizzleNight },
    302: { day: Drizzle, night: Drizzle },

    500: { day: PartlyCloudyRainDay, night: PartlyCloudyRainNight },
    501: { day: Rain, night: Rain },
    502: { day: Rain, night: Rain },
    511: { day: Hail, night: Hail },
    520: { day: PartlyCloudyRainDay, night: PartlyCloudyRainNight },
    521: { day: PartlyCloudyRainDay, night: PartlyCloudyRainNight },
    522: { day: Rain, night: Rain },
    600: { day: PartlyCloudySnowDay, night: PartlyCloudySnowNight },
    601: { day: Snow, night: Snow },
    602: { day: Snow, night: Snow },
    610: { day: Hail, night: Hail },
    611: { day: PartlyCloudyHailDay, night: PartlyCloudyHailNight },
    612: { day: Hail, night: Hail },
    621: { day: Snow, night: Snow },
    622: { day: Snow, night: Snow },
    623: { day: Snow, night: Snow },

    700: { day: Mist, night: Mist },
    711: { day: Mist, night: Mist },
    721: { day: Mist, night: Mist },
    731: { day: Mist, night: Mist },
    741: { day: Mist, night: Mist },
    751: { day: Mist, night: Mist },

    800: { day: ClearDay, night: ClearNight },
    801: { day: PartlyCloudyDay, night: PartlyCloudyNight },
    802: { day: PartlyCloudyDay, night: PartlyCloudyNight },
    803: { day: PartlyCloudyDay, night: PartlyCloudyNight },
    804: { day: Cloudy, night: Cloudy },

    900: { day: Rain, night: Rain },
}

interface WeatherIconProps extends React.SVGProps<SVGSVGElement> {
    weatherBitCode: number;
    isDaytime: boolean;
}

export const WeatherIcon: React.FC<WeatherIconProps> = (props) => {
    const { weatherBitCode, isDaytime, ...rest } = props;
    const IconElement = (isDaytime ? ICONS[weatherBitCode]?.day : ICONS[weatherBitCode]?.night) ?? <></>;
    return (<IconElement {...rest} />);
}