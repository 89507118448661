import React from 'react';

import { ContentScreen } from '../layouts/ContentScreen';

export default function Charters() {
    return (
        <ContentScreen>
            <div>Charters</div>
        </ContentScreen>
    );
}