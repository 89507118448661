import { IsEmail, IsNotEmpty } from 'class-validator';

export interface IUserLogin {
	emailAddress: string;

	password: string;

	rememberMe: boolean;

}
export class UserLogin {
	constructor(data: IUserLogin) {
		Object.assign(this, data);
		if (!data) return;
	}

	@IsNotEmpty()
	@IsEmail({}, {
	  "message": "The $property field is not a valid e-mail address."
	})
	emailAddress: string;

	@IsNotEmpty()
	password: string;

	rememberMe: boolean;

}

