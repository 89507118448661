import React, { MouseEventHandler } from 'react';
import { Card, CardImg } from 'react-bootstrap';
import { BoatLanding } from '../../models';

interface BoatLandingCardProps {
    boatLanding: BoatLanding,
    onClick?: MouseEventHandler
}

export const BoatLandingCard: React.FC<BoatLandingCardProps> = ({ boatLanding, onClick }) => {

    return (
        <Card className="boat-landing-card" onClick={onClick}>
            <Card.Img variant="top" src={"/img/sc-boat-ramps/" + boatLanding.imageUrl} />
            <Card.Body>
                <Card.Title>
                    {boatLanding.title}
                </Card.Title>
                <Card.Text>
                    {boatLanding.cityStateZip}<br />
                    {boatLanding.bodyOfWater}
                </Card.Text>
            </Card.Body>
        </Card>
        );
}