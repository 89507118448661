import React, { useContext, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { getZoomForRadius } from '../../helpers/map';

import { ContentScreen } from '../layouts';
import { Bounds, Map, MapUrlParamType, ParseMapUrlParams } from '../shared/Map';
import {  BoatLandingsPageModel, IBoatLandingsPageModel, FishingSpot, FishingSpotsPageModel, FishingSpotCluster } from '../../models';

import { CardDeck, Col, Container, Row } from 'react-bootstrap';
import { usePageModel, usePosition } from '../../hooks';

import { FishingSpots as FishingSpotsApi } from '../../api';
import { BodyRow, BodyRowContent, BodyRowHeader, FishingSpotCard } from '../shared';
import ReactPaginate from 'react-paginate';
import { Pages } from '../shared/Pages';
import { staticRenderContext } from '../../StaticRenderContext';

const popup = (spot: FishingSpotCluster) => {
    if (spot.numberOfPoints == 1) {
        return (
            <div className="boat-landing-popup">
                <Container className="popup-title" fluid>
                    {spot.details.title}
                </Container>
                <Container fluid>
                    {spot.details.description}
                </Container>
            </div>
        );
    } else {
        return (
            <div className="boat-landing-popup">
                <Container className="popup-title" fluid>
                    {spot.summary}
                </Container>
            </div>
            );
    }
}

const mapLoaded = (map: google.maps.Map) => {
    // https://developers.google.com/maps/documentation/javascript/examples/maptype-image-overlay
    var imageMapType = new google.maps.ImageMapType({

        getTileUrl: function (coord: google.maps.Point, zoom: number) {

            var z2 = Math.pow(2, zoom);
            var size = 256 / z2;
            var x = coord.x;
            if (coord.x < 0) {
                x = coord.x + z2;
            }

            var proj = map.getProjection();
            var tileBounds = new google.maps.LatLngBounds(
                proj?.fromPointToLatLng(new google.maps.Point(x * size, (coord.y + 1) * size)),
                proj?.fromPointToLatLng(new google.maps.Point((x + 1) * size, coord.y * size))
            );

            //                if (!mapBounds.intersects(tileBounds) || zoom < mapMinZoom || zoom > mapMaxZoom) return "";

            var tiles = '//tileservice.charts.noaa.gov/tiles/50000_1/{z}/{x}/{y}.png';
            tiles = tiles.replace('{z}', zoom.toString()).replace('{x}', coord.x.toString()).replace('{y}', coord.y.toString());
            return tiles;
        },
        tileSize: new google.maps.Size(256, 256),

        opacity: 1.0,
        name: 'Tiles'
    });

    map.overlayMapTypes.push(imageMapType);
}

const FishingSpots: React.FC<MapUrlParamType> = (props) => {
    const staticContext = useContext(staticRenderContext);
    const params = useParams();
    var model = usePageModel(FishingSpotsPageModel, staticContext);
    const urlParams = ParseMapUrlParams(params);

    const currentLocation = usePosition(false);
    const [startingIndex, setStartingIndex] = useState(0);
    const [fishingSpots, setFishingSpots] = useState(model?.reefs);
    const [zoom, setZoom] = useState(urlParams.zoom ?? 10);
    const [latitude, setLatitude] = useState(urlParams.latitude ?? currentLocation.latitude ?? undefined);
    const [longitude, setLongitude] = useState(urlParams.longitude ?? currentLocation.longitude ?? undefined);
    const itemsPerPage = 12;
    var navigate = useNavigate();
    
    const pageChanged = (selectedItem: { selected: number }) => {
        setStartingIndex(selectedItem.selected * itemsPerPage);
    };
    
    const mapChanged = (zoom: number, bounds: Bounds) => {
        //Load new points for our map
        setZoom(zoom);
        var centerLat = (bounds.ne.lat + bounds.sw.lat) / 2.0;
        var centerLng = (bounds.nw.lng + bounds.se.lng) / 2.0;
        var spots = FishingSpotsApi.getFishingSpotGroups(zoom, centerLat, centerLng, Math.abs(bounds.ne.lat - centerLat), Math.abs(bounds.ne.lng - centerLng));
        
        spots.then((value: FishingSpotCluster[] | undefined) => {
            if (value)
                setFishingSpots(value);
        });

        window.history.replaceState(null, "", Pages.FishingSpots.getUrl({ zoom: zoom, latitude: centerLat, longitude: centerLng }));
    };
    const fishingSpotClicked = (spot: FishingSpot) => {
        var newHref = Pages.FishingSpot.getUrl({ locationId: `${spot.title}-${spot.externalId}` });
        window.location.href = newHref;
    };
    const handleIconClick = (spot: FishingSpotCluster) => {
        if (spot.numberOfPoints == 1)
            fishingSpotClicked(spot.details);
        else {
            //zoom map in and center on this lat lon
            var newZoom = getZoomForRadius(spot.radius);
            if (newZoom <= zoom)
                newZoom = zoom + 1;
            setZoom(newZoom);
            setLatitude(spot.latitude);
            setLongitude(spot.longitude);

            window.history.replaceState(null, "", Pages.FishingSpots.getUrl({ zoom: zoom, latitude: latitude, longitude: longitude }));
        }
    }

    if (!model)
        return <></>

    return (
        <ContentScreen>
            <Map containerId="locationMap"
                icons={fishingSpots}
                popup={popup}
                centerLat={latitude}
                centerLng={longitude}
                zoom={zoom}
                onMapLoaded={mapLoaded}
                onIconClick={handleIconClick}
                onChange={mapChanged} />
            <Row className="location-details">
                <Col xs={6}>
                    <h1 className="location-title">Fishing Spots and Reefs</h1>
                </Col>
            </Row>

            <BodyRow>
                <BodyRowHeader hideSeparator={true}>
                    <Col>
                        <h1>Nearby Reefs / Fishing Spots</h1>
                    </Col>
                </BodyRowHeader>
                <BodyRowContent>
                    <Col>
                    <CardDeck>
                        {fishingSpots &&
                                fishingSpots.slice(startingIndex, startingIndex + itemsPerPage).map((fsc) => {
                                    return (<FishingSpotCard key={fsc.summary} fishingSpot={fsc} onClick={() => { handleIconClick(fsc); }} />);
                            })
                        }
                        </CardDeck>
                    </Col>
                </BodyRowContent>
                {fishingSpots && fishingSpots.length > itemsPerPage &&
                    <BodyRowContent>
                        <Col>
                            <ReactPaginate
                                pageCount={Math.ceil(fishingSpots.length / itemsPerPage)}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                containerClassName="pagination"
                                activeClassName="active"
                                breakClassName="break"
                                previousLabel="<"
                                nextLabel=">"
                                onPageChange={pageChanged}
                            />
                        </Col>
                    </BodyRowContent>
                }
            </BodyRow>
        </ContentScreen>
    );
};

export default FishingSpots;