import { Station, IStation } from "./TidesApi.Models.Station.gen";
import { DailyTide, IDailyTide } from "./TidesApi.Models.DailyTide.gen";

export interface ITidesPageModel {
	tideStation: IStation;

	stations: IStation[];

	dailyTideForecasts: IDailyTide[];

}
export class TidesPageModel {
	constructor(data: ITidesPageModel) {
		Object.assign(this, data);
		if (!data) return;
		this.tideStation = new Station(data.tideStation);
		this.stations = data.stations.map((s: any) => new Station(s));
		this.dailyTideForecasts = data.dailyTideForecasts.map((d: any) => new DailyTide(d));
	}

	tideStation: Station;

	stations: Station[];

	dailyTideForecasts: DailyTide[];

}

