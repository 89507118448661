import { FishingSpot, IFishingSpot } from "./FishingSpot.gen";
import { MapIconTypes } from "./MapIconTypes.gen";

export interface IFishingSpotCluster {
	mapIcon: MapIconTypes;

	bearingAsCardinal: string;

	summary: string;

	numberOfPoints: number;

	longitude: number;

	latitude: number;

	radius: number;

	distance: number;

	bearing: number;

	details: IFishingSpot;

}
export class FishingSpotCluster {
	constructor(data: IFishingSpotCluster) {
		Object.assign(this, data);
		if (!data) return;
		this.details = new FishingSpot(data.details);
	}

	mapIcon: MapIconTypes;

	bearingAsCardinal: string;

	summary: string;

	numberOfPoints: number;

	longitude: number;

	latitude: number;

	radius: number;

	distance: number;

	bearing: number;

	details: FishingSpot;

}

