export interface ISponsor {
	name: string;

	url: string;

	logo: string;

	imageWidth: number|undefined;

	imageHeight: number|undefined;

	description: string;

	tagline: string;

	id: string;

}
export class Sponsor {
	constructor(data: ISponsor) {
		Object.assign(this, data);
		if (!data) return;
	}

	name: string;

	url: string;

	logo: string;

	imageWidth: number|undefined;

	imageHeight: number|undefined;

	description: string;

	tagline: string;

	id: string;

}

