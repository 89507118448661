import { Compare } from './validators';
import { IsNotEmpty } from 'class-validator';

export interface IPasswordReset {
	userId: string;

	resetToken: string;

	password: string;

	passwordConfirmation: string;

}
export class PasswordReset {
	constructor(data: IPasswordReset) {
		Object.assign(this, data);
		if (!data) return;
	}

	userId: string;

	resetToken: string;

	@IsNotEmpty()
	password: string;

	@IsNotEmpty()
	@Compare("password", {
	  "message": "The passwords do not match."
	})
	passwordConfirmation: string;

}

