import React from 'react';
import { Card } from 'react-bootstrap';
import { FaCar } from 'react-icons/fa';
import { GiFishing, GiSpeedBoat, GiWoodenPier } from 'react-icons/gi';

type IconTypes = "car" | "pier" | "fish" | "lane";

type DataCardProps = {
    title: string,
    value: string | number,
    icon: IconTypes
};

const getIcon = (icon: IconTypes) => {
    switch (icon) {
        case "car":
            return (<FaCar size="2em" />);
        case "pier":
            return (<GiWoodenPier size="2em" />);
        case "fish":
            return (<GiFishing size="2em" />);
        case "lane":
            return (<GiSpeedBoat size="2em" />);
    }
    
    return <></>
}

export const DataCard = (props: DataCardProps) => {
    return (
        <Card className="data-card">
            <Card.Body className="card-body">
                <div className="media">
                    <div className="icon-container bg-icon avatar-lg text-center bg-light">
                        {props.icon &&
                            getIcon(props.icon)
                        }
                    </div>
                    <div className="media-body">
                        <p className="title">
                            {props.title}
                        </p>
                        <h2 className="value" >
                            {props.value}
                        </h2>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}