import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentScreen } from '../layouts';
import { Map, Bounds, MapUrlParamType, ParseMapUrlParams } from '../shared/Map';
import { BoatLanding, BoatLandingCluster, BoatLandingsPageModel } from '../../models';

import { CardDeck, Col, Container, Row } from 'react-bootstrap';
import { usePageModel, usePosition } from '../../hooks';
import { BoatLandingCard, BodyRow, BodyRowContent, BodyRowHeader } from '../shared';
import ReactPaginate from 'react-paginate';
import { BoatLandings as BoatLandingsApi } from '../../api';
import { staticRenderContext } from '../../StaticRenderContext';

const toLandingView = (landing: BoatLandingCluster) => {
    const { details, latitude, longitude } = landing;
    return (<div key={details.id}>{details.title} ({latitude}, {longitude}) </div>);
}
const boatLandingGroupClicked = (landingGroup: BoatLandingCluster) => {
    if (landingGroup.numberOfPoints == 1)
        window.location.href = `/sc-boat-landing/${landingGroup.details.id}`;
    else {
        //zoom map in 
    }
};

const popup = (landing: BoatLandingCluster) => (
    <div className="boat-landing-popup">
        <Container className="popup-image" fluid>
            <img src={"/img/sc-boat-ramps/" + landing.details.imageUrl} />
        </Container>
        <Container className="popup-title" fluid>
            {landing.details.title}
        </Container>
    </div>
);

const BoatLandings: React.FC<MapUrlParamType> = (props) => {
    const staticContext = useContext(staticRenderContext);
    const model = usePageModel(BoatLandingsPageModel, staticContext);
    const currentLocation = usePosition(false);
    const [startingIndex, setStartingIndex] = useState(0);
    const [landings, setLandings] = useState(model?.landings);
    const itemsPerPage = 12;
    const navigate = useNavigate();
    const params = useParams();
    const urlParams = ParseMapUrlParams(params);
    
    //If we have no model, just return empty
    if (!model)
        return <></>

    const boatLandingClicked = (landing: BoatLanding) => {
        navigate(`/sc-boat-landing/${landing.id}`);
    };

    const pageChanged = (selectedItem: { selected: number }) => {
        setStartingIndex(selectedItem.selected * itemsPerPage);
    };
    const mapChanged = (zoom: number, bounds: Bounds) => {
        //Load new points for our map
        var centerLat = (bounds.ne.lat + bounds.sw.lat) / 2.0;
        var centerLng = (bounds.nw.lng + bounds.se.lng) / 2.0;
        var boatLandings = BoatLandingsApi.getBoatLandingGroups(zoom, centerLat, centerLng, Math.abs(bounds.ne.lat - centerLat), Math.abs(bounds.ne.lng - centerLng));
        
        boatLandings.then((value: BoatLandingCluster[] | undefined) => {
            if (value)
               setLandings(value);
        });

        window.history.replaceState(null, "", `/sc-boat-landings/${zoom}/${centerLat}/${centerLng}`);
    };

    return (
        <ContentScreen>
            <Map containerId="locationMap"
                icons={landings}
                popup={popup}
                centerLat={urlParams.latitude ?? currentLocation.latitude ?? undefined}
                centerLng={urlParams.longitude ?? currentLocation.longitude ?? undefined}
                zoom={urlParams.zoom ?? undefined}
                onIconClick={boatLandingGroupClicked}
                onChange={mapChanged} />
            <Row className="location-details">
                <Col xs={6}>
                    <h1 className="location-title">Boat Landings Map</h1>
                </Col>
            </Row>

            <BodyRow>
                <BodyRowHeader hideSeparator={true}>
                    <Col>
                        <h1>Nearby Boat Launches / Landings</h1>
                    </Col>
                </BodyRowHeader>
                <BodyRowContent>
                    <Col>
                        <CardDeck>
                            {landings && 
                                landings.slice(startingIndex, startingIndex + itemsPerPage).map((blg) => {
                                    var bl = blg.details as BoatLanding;
                                    if (bl)
                                        return (<BoatLandingCard key={bl.id} boatLanding={bl} onClick={() => { boatLandingClicked(bl); }} />);
                                    return (<></>);
                                })
                            }
                        </CardDeck>
                    </Col>
                </BodyRowContent>
                {landings && landings.length > 0 &&
                    <BodyRowContent>
                        <Col>
                            <ReactPaginate
                                pageCount={Math.ceil(landings.length / itemsPerPage)}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                containerClassName="pagination"
                                activeClassName="active"
                                breakClassName="break"
                                previousLabel="<"
                                nextLabel=">"
                                onPageChange={pageChanged}
                            />
                        </Col>
                    </BodyRowContent>
                }
            </BodyRow>
        </ContentScreen>
        
            
    );
};

export default BoatLandings;