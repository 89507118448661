import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

type BodyRowBase = {
    children?: ReactNode,
    className?: string
};


type BodyRowProps = {} & BodyRowBase;

export const BodyRow = (props: BodyRowProps) => {
    return (
        <Row className={"body-row " + props.className}>
            <Col className="body-col">
                {props.children}
            </Col>
        </Row>
    );
}

type BodyRowHeaderProps = {
    hideSeparator?: boolean
} & BodyRowBase;

export const BodyRowHeader = (props: BodyRowHeaderProps) => {
    return (
        <>
            <Row className="header">
                {props.children}
            </Row>
            {!props.hideSeparator && <hr />}
        </>
    );

}

type BodyRowContentProps = {
    className?: string
} & BodyRowBase;

export const BodyRowContent = (props: BodyRowContentProps) => {
    return (
        <Row className={ props.className }>
            {props.children}
        </Row>
    );
}


