import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { Header, Footer } from '../shared';


type Props = {
    children?: ReactNode
};

export function ContentScreen({ children }: Props) {
    return (
        <>
            <Header />
            <main>
                { children }
            </main>
            <Footer />
        </>
    );
}