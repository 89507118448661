import React, { MouseEventHandler } from 'react';

import { WeatherForecast } from '../../models';
import { WeatherIcon } from './WeatherIcon';

import { ReactComponent as DripIcon } from '../../assets/images/Drip.svg';
import { ReactComponent as EmptyDial } from '../../assets/images/EmptyDial.svg';
import { ReactComponent as DialIndicator } from '../../assets/images/DialIndicator.svg';
import { ReactComponent as EmptyDialWithLines } from '../../assets/images/EmptyDialWithLines.svg';
import { ReactComponent as EmptyDialWithColors } from '../../assets/images/EmptyDialWithColors.svg';
import { ReactComponent as WaveDialIndicator } from '../../assets/images/WaveDialIndicator.svg';
import { ReactComponent as WindDialIndicator } from '../../assets/images/WindDialIndicator.svg';
import { ReactComponent as WindIcon } from '../../assets/images/WindIcon.svg';

interface WeatherDayCardProps {
    forecast: WeatherForecast,
    collapsed?: boolean,
    onClick?: MouseEventHandler
}

export const WeatherDayCard: React.FC<WeatherDayCardProps> = ({ forecast, collapsed, onClick }) => {
    return (
        <div className={"weather-card" + (collapsed ? " collapsed" : "")} onClick={onClick} >
            <div className="day">
                {forecast.dateName}
            </div>
            { /*<div className="daily-toggle">DAY | HOUR</div>*/ }

            <div className="weather-icon">
                <i className="@Model.Icon.ToString()"></i>
                <WeatherIcon weatherBitCode={ forecast.weatherbitCode } isDaytime={ forecast.isDaytime } width={100} />
            </div>
            <div className="forecast-text">
                <div className="temperature">
                    {forecast.currentTemperature &&
                        <>
                            {forecast.currentTemperature} <span className="tempUnit">&deg;F</span>
                            <h3>{forecast.tempHigh}&deg; | {forecast.tempLow}&deg;</h3>
                        </>
                    }

                    {!forecast.currentTemperature &&
                        <>
                            {forecast.tempHigh}&deg; | {forecast.tempLow}
                            <span className="tempUnit">&deg;F</span>
                        </>
                    }

                    
                </div>
                <div className="chance-of-precip">
                    <DripIcon className="icon" />
                    <label>{forecast.chanceOfPrecipitation}%</label>
                </div>
                <div className="wind">
                    <WindIcon className="icon" />
                    <label>{forecast.windSpeed} kts</label>
                </div>
            </div>
            <div className="weather-dials">
                <div className="dial">
                    <EmptyDialWithLines className="background" />
                    <WaveDialIndicator className="indicator" style={{ transform: `translate(-100%) rotate(${forecast.waveDirection}deg)` }} />
                    <h3>Wave Direction</h3>
                    {forecast.waveDirectionCardinal} ({forecast.waveDirection}°)
                </div>
                <div className="dial">
                    <EmptyDialWithColors className="background" />
                    <DialIndicator className="indicator" style={{ transform: `translate(-100%) rotate(${ Math.min(-120 + 40 * (Math.max(forecast.waveHeightMax - 1, 0)), 60)}deg)` }} />
                    <h3>Wave Height</h3>
                    {forecast.waveHeightMax == forecast.waveHeightMin || forecast.waveHeightMin <= 0 ? forecast.waveHeightMax : forecast.waveHeightMin + "-" + forecast.waveHeightMax} ft
                </div>
                <div className="dial">
                    <EmptyDialWithColors className="background" />
                    <DialIndicator className="indicator" style={{ transform: `translate(-100%) rotate(${ Math.min(-120 + 24 * (15 - forecast.wavePeriod), 60)}deg)` }} />
                    <h3>Wave Period</h3>
                    {forecast.wavePeriod} s
                </div>
                <div className="dial">
                    <EmptyDialWithLines className="background" />
                    <WindDialIndicator className="indicator" style={{ transform: `translate(-100%) rotate(${forecast.windDirection}deg)` }} />
                    <h3>Wind Speed</h3>
                    {forecast.windSpeed} kts ({forecast.windDirectionCardinal})
                </div>
            </div>
        </div >
    );
}