import React, { useRef, useState, MouseEvent, ReactElement, useEffect } from 'react';

import { PropsWithChildren } from 'react';
import GoogleMapReact from 'google-map-react'
import { MapIcon } from './MapIcon';
import { usePopper } from 'react-popper';
import { MapIconTypes } from '../../models';

interface ILocation {
    longitude: number,
    latitude: number,
    id?: string,
    title?: string,
    numberOfPoints?: number,
    radius?: number,
    mapIcon?: MapIconTypes,
}

interface MapProps<T extends ILocation> {
    containerId?: string,
    icons?: T[],
    zoom?: number,
    centerLat?: number,
    centerLng?: number,
    popup?: (item: T) => React.ReactNode,
    onMapLoaded?: (map: google.maps.Map) => void,
    onIconClick?: (location: T) => void,
    onChange?: (zoom: number, bounds: GoogleMapReact.Bounds) => void,
    isIconActive?: (location: T) => boolean

};
export type { Bounds } from 'google-map-react';

export type MapUrlParamType = { zoom?: string, latitude?: string, longitude?: string };
export const ParseMapQueryString = (q: URLSearchParams) => {
    var zoom;
    var latitude;
    var longitude;

    if (q.get('zoom'))
        zoom = parseInt(q.get('zoom')!);
    else if (q.get('z'))
        zoom = parseInt(q.get('z')!);

    if (q.get('latitude'))
        latitude = parseFloat(q.get('latitude')!);
    else if (q.get('lat'))
        latitude = parseFloat(q.get('lat')!);

    if (q.get('longitude'))
        longitude = parseFloat(q.get('longitude')!);
    else if (q.get('lon'))
        longitude = parseFloat(q.get('lon')!);
    else if (q.get('lng'))
        longitude = parseFloat(q.get('lng')!);

    return { zoom, latitude, longitude };
}
export const ParseMapUrlParams = (params: MapUrlParamType) => {
    var zoom;
    var latitude;
    var longitude;

    if (params.zoom) {
        zoom = parseInt(params.zoom);
    }
    if (params.latitude && params.longitude) {
        latitude = parseFloat(params.latitude);
        longitude = parseFloat(params.longitude);
    }
    
    return { zoom, latitude, longitude };
}

export const Map = <T extends ILocation>(props: PropsWithChildren<MapProps<T>>): ReactElement | null => {
    const defaultZoom = 11;
   
    const mapRef = useRef(null);
    const [zoom, setZoom] = useState(props.zoom ? props.zoom : defaultZoom);
    useEffect(() => {
        if (props.zoom && props.zoom != zoom) {
            setZoom(props.zoom);
            console.log(`adjusting zoom to ${props.zoom}`)
        }
    }, [props.zoom]);

    //Popover elements
    const [hoveredElement, setHoveredElement] = useState<HTMLElement>();
    const [hoveredLocation, setHoveredLocation] = useState<T>();
    const popperElement = useRef<HTMLDivElement>(null);
    const arrowElement = useRef<HTMLDivElement>(null);
    const { styles, attributes } = usePopper(hoveredElement, popperElement.current, {
        placement: "top",
        modifiers: [{ name: 'arrow', options: { element: arrowElement.current } }],
    });

    const handleIconClick = () => {
        if (props.onIconClick && hoveredLocation)
            props.onIconClick(hoveredLocation);
    };

    const handleMouseEnter = (location: T, target: HTMLImageElement) => {
        setHoveredLocation(location);
        setHoveredElement(target);
        popperElement.current?.setAttribute('data-show', '');
    };
    
    const handleMouseLeave = () => {
        setHoveredLocation(undefined);
        setHoveredElement(undefined);
        popperElement.current?.removeAttribute('data-show');
    };
    
    return (
        <div id={ props.containerId } style={{ height: '500px', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyAtZ7GLdPhKi_BHyr2zN0H2YPdNPenpvyQ" }}
                center={{ lat: props.centerLat ? props.centerLat : 32.7765, lng: props.centerLng ? props.centerLng : -79.9311 }}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                    if (props.onMapLoaded)
                        props.onMapLoaded(map);
                }}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom);
                  
                    if (props.onChange)
                        props.onChange(zoom, bounds);
                }}
            >
                {
                    props.icons?.map((value) =>
                        <MapIcon key={value.latitude + ", " + value.longitude} id={value.id} height={50} width={50}
                            pointCount={value.numberOfPoints}
                            icon={value.mapIcon}
                            lat={value.latitude} lng={value.longitude}
                            onClick={handleIconClick}
                            onMouseEnter={(event) => handleMouseEnter(value, event.currentTarget)}
                            onMouseLeave={handleMouseLeave}
                            active={!props.isIconActive || props.isIconActive(value)}
                        />
                            
                        
                    )
                }
            </GoogleMapReact>
            <div id="map-popover" ref={popperElement} style={styles.popper} {...attributes.popper}>
                { hoveredLocation && props.popup ? props.popup(hoveredLocation) : undefined }
                <div id="map-arrow" ref={arrowElement} style={styles.arrow} />
            </div>
        </div>            
    );
}
