import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

type FooterLinkGroupProps = {
    title: string,
    children?: ReactNode,
};

export const FooterLinkGroup = (props: FooterLinkGroupProps) => {
    return (
        <>
            <h2>{props.title}</h2>
            <ul>
                {props.children}
            </ul>
        </>
    );
}

type FooterLinkProps = {
    href: string,
    children?: ReactNode,
};

export const FooterLink = (props: FooterLinkProps) => {
    return (
        <li><a href={props.href}>{props.children}</a></li>
    );

}


