import React, { MouseEventHandler, useState } from 'react';
import { OverlayTrigger, Tooltip, Button, Popover, Overlay } from 'react-bootstrap'
import { MapIconTypes } from '../../models/MapIconTypes.gen';
 
interface MapIconProps
    extends React.InputHTMLAttributes<HTMLImageElement> {
    lat: number,
    lng: number,
    icon?: MapIconTypes,
    $hover?: boolean,
    pointCount?: number,
    active?: boolean
};

function getMapIcon(mapIcon?: MapIconTypes) {
    var imgName = "generic";
    if (mapIcon) {
        switch (mapIcon) {
            case MapIconTypes.Fish:
                imgName = "fish";
                break;
            case MapIconTypes.BoatLanding:
                imgName = "boat-landing";
                break;
            case MapIconTypes.Buoy:
                imgName = "buoy";
                break;
            case MapIconTypes.Reef:
                imgName = "reef";
                break;
        }
    }
    return `/img/map-icon-${imgName}.png`;
}

export const MapIcon: React.FC<MapIconProps> = (props) => {
    const { height, width, $hover, onClick, id, onMouseEnter, onMouseLeave, active } = props;
    const imgName = getMapIcon(props.icon);
    if ((props.pointCount ?? 0) > 1) {
        return (
            <div className="mapIconContainer"
                style={{
                    filter: $hover ? "brightness(1.25)" : "brightness(1.0)",
                        
                }}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >

                <img src="/img/map-icon-group.png"  width={width} height={height}/>
                <p>{props.pointCount}</p>
            </div>
        );
    }
    return (
        <img src={imgName}
            className="mapIcon"
            width={width}
            height={height}
            style={{
                filter: $hover ? `brightness(1.25) grayscale(0)` : `brightness(1.0) grayscale(${(active??true)?"0":"1"})`
            }}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        />        
    );
}