import { ApiResponse, User } from "../models";
import { sessionValue } from "../helpers";

const DEFAULT_GET_OPTIONS: RequestInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
        Accept: 'application/json',
    },
};
Object.freeze(DEFAULT_GET_OPTIONS);

const DEFAULT_POST_OPTIONS: RequestInit = {
    ...DEFAULT_GET_OPTIONS,
    headers: {
        ...DEFAULT_GET_OPTIONS.headers,
        'Content-Type': 'application/json',
    },
    method: 'POST',
};
Object.freeze(DEFAULT_POST_OPTIONS);

const DEFAULT_DELETE_OPTIONS: RequestInit = {
    ...DEFAULT_GET_OPTIONS,
    method: 'DELETE'
};
Object.freeze(DEFAULT_DELETE_OPTIONS);

const REACT_APP_TSPS_CORE_API_ROOT_URL = process.env.REACT_APP_TSPS_CORE_API_ROOT_URL;

export type ApiResult<T> = Promise<T | undefined>;
export const ApiResult = Promise;

class AppError extends Error {
    private _messages: string[];
    public get messages() { return this._messages; }

    constructor(messages: string[]) {
        const message = messages.join("  ");
        super(message);

        this._messages = messages;
        this.name = 'TspsError';
    }
}

export class UserError extends AppError {
    constructor(messages: string[]) {
        super(messages);
        this.name = 'UserError';
    }
}

export class ServerError extends AppError {
    constructor(messages: string[]) {
        super(messages);
        this.name = 'ServerError';
    }
}

function isUserError(statusCode: number): boolean {
    return statusCode >= 400 && statusCode < 500;
}

function isServerError(statusCode: number): boolean {
    return statusCode >= 500 && statusCode < 600;
}

function validateResponse(status: number, errors: string[]): void {
    if (isUserError(status)) { throw new UserError(errors); }
    if (isServerError(status)) { throw new ServerError(errors); }
}

async function getValidatedResponseData<T>(response: Response): ApiResult<T> {
    const responseText = await response.text();

    if (responseText.length === 0) {
        validateResponse(response.status, [response.statusText]);
        return;
    }

    const { status, errors, data } = JSON.parse(responseText) as ApiResponse<T>;
    validateResponse(status, errors);
    return data;
}

function addAuthForOptions(options: RequestInit): RequestInit {
    const { headers } = options;
    const [user] = sessionValue<User>('currentUser');

    if (options.method === 'GET') { return options; }

    const authenticatedHeaders = { ...headers, 'X-AUTH-TOKEN': user?.authToken || '' };
    return { ...options, headers: authenticatedHeaders };
}

async function apiFetch<T>(path: string, options: RequestInit): ApiResult<T> {
    const url = [REACT_APP_TSPS_CORE_API_ROOT_URL, path].join('');

    const authenticatedOptions = addAuthForOptions(options);
    const response = await fetch(url, authenticatedOptions);

    return getValidatedResponseData(response);
}

export async function get<T>(path: string): ApiResult<T> {
    return apiFetch<T>(path, DEFAULT_GET_OPTIONS);
}

export async function del<T>(path: string): ApiResult<T> {
    return apiFetch<T>(path, DEFAULT_DELETE_OPTIONS);
}

export async function post<T>(path: string, body: any): ApiResult<T> {
    const options = { ...DEFAULT_POST_OPTIONS, body: JSON.stringify(body) };
    return apiFetch<T>(path, options);
}